body {
  padding: 20px;
}

#content {
  width: 100%;
}

.react-grid-layout {
  display: flex;
  flex-direction: row;
  background-color: #13697E;
  color: white;
  margin-top: 2%;
  border-radius: 20px 20px 0 0;
  /* justify-content: center; */
  /* height: 40%; */
  /* padding: 4%; */
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  /* background: #ccc; */
  /* border: 1px solid black; */
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
  background: #cce;
}

.headerText{
  /* font-size: 18px;
  text-align: left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
  cursor: pointer; */
  color: white;
}

.mobbody{
  color: black;
}
.bodytext{
  font-size: auto;
  /* text-align: center; */
  /* position: absolute; */
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  /* margin: auto; */
  height: 24px;
  /* cursor: pointer; */
  color: black;
}

.bodytext span {
  color: black;
  overflow-wrap: break-word;
}

.payload_view_table_actions {
  /* margin-top: 4%; */
}

.payload_view_table_actions button {
height: 37px;
margin: 5px;
width: 40px;
}

.react-grid-item .text {
  font-size: auto;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
  cursor: pointer;
  color: black;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}

.toolbox__items {
  display: block;
}

.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}


/* added by vaibhav */

.pv-block-body-dd {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  margin-top: 0%;
  border-radius: 0;
} 

.pv-block-body-dd input{
  color: black;
  /* border-radius: 0 0 20px 20px; */
} 


.pv-block-body {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  margin-top: 0%;
  border-radius: 0 0 20px 20px;
  margin: auto;
  padding: 3%;
} 

.pv-block-error-msg {
  font-size: 14px;
  border: 2px solid;
  border-radius: 20px;
  /* margin: auto; */
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
  background-color: whitesmoke;
  height: 60px;
  display: flex;
  flex-direction: column;
  /* padding-top: 2%; */
  /* width: 50%; */
}
