.pv-block {
    justify-content: center;
    border-radius: 20px;
    border: 1px solid pink;
    background-color: whitesmoke;
    margin: auto;
    height: auto;
    width: 60%;
    margin-bottom: 2%;
}

.pv-block-header {
    display: flex;
    flex-direction: row;
    background-color: #13697E;
    color: white;
    margin-top: 0%;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    height: 25%;
    padding: 4%;
}

.pv-block-header>.hhid {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.pv-block-header>.hhid>.hh-name {
    font-size: 18px;
    font-weight: bold;
}

.pv-block-header>.hhid>.hh-id {
    font-size: 16px;
}

.pv-block-header > .buttons{
    display: flex;
    flex-direction: row;
    padding: 2%;
}

.pv-block-header > .buttons > .seloptionDiv{
    margin-right: 10px;
}



.pv-block-body {
    display: flex;
    flex-direction: column;

}

.pv-block-body>.coll-block {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 3%;
    font-size: 18px;
}


.pv-block-body>.coll-block>.coll-date .coll-mode .coll-dt {
    display: flex;
    flex-direction: column;
}

.coll-details-data {
    font-weight: bold;
}

.coll-block-edit{

    margin: auto;
    margin-top: 2%;
    text-align: center;
    background-color: whitesmoke;
    height: 90px;
    display: flex;
    flex-direction: column;
    width: 98%;
}

.coll-block-edit >.coll-block-edit-input {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 1%;
}

.coll-details-data-amt {
    background-color: aqua;
    border-radius: 10px;
    width: 20%;
    margin: auto;
    font-weight: bold;
}

.pv-block-error-msg {
    border: 2px solid;
    border-radius: 20px;
    margin: auto;
    margin-top: 8%;
    text-align: center;
    background-color: whitesmoke;
    height: 90px;
    display: flex;
    flex-direction: column;
    padding-top: 2%;
    width: 50%;
}

.err-block-header {
    font-weight: bold;
    font-size: 18px;
}

.updateButtonDiv{
    margin-top: 2%;
}

@media(max-width:407px) {
    .pv-block {
        justify-content: center;
        border-radius: 20px;
        border: 1px solid pink;
        background-color: whitesmoke;
        margin: auto;
        height: auto;
        width: 100%;

    }

    .pv-block-header {
        display: flex;
        flex-direction: row;
        background-color: #13697E;
        color: white;
        margin-top: 0%;
        border-radius: 20px 20px 0 0;
        justify-content: center;
        height: 25%;
        padding: 2%;
    }

    .pv-block-header>.hhid {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-left: 2%;
    }

    .pv-block-header>.hhid>.hh-name {
        font-size: 14px;
        font-weight: bold;
    }

    .pv-block-header>.hhid>.hh-id {
        font-size: 12px;
    
    }

    .pv-block-header>.buttons {
        width: 40%;
        display: flex;
        flex-direction: column;
        
    }

    
    .coll-date, .coll-mode, .coll-dt{
        font-size: 12px;
    }
    
}