body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Permission_functionality_item__2QLa7{
    width: 100%;
    border-radius: 4px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0px;
    /* outline: 1px solid rgb(180, 211, 241); */
    outline:1px solid rgb(244, 244, 244);
}

.Permission_functionality_item_selected__1SN0H{
    width: 100%;
    border-radius: 4px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0px;
    /* outline: 1px solid rgb(180, 211, 241); */
    outline:1px solid rgb(244, 244, 244);
   transition: all 0.3s ease;
    background-color: rgb(244, 244, 244);
}
.Permission_functionality_item__2QLa7:hover{
    transition: all 0.3s ease;
    background-color: rgb(237, 237, 237);

}
.Permission_functionality_drawer_button__1Fy3g{
    position: fixed;
    top: 20px;
    right:0;
}

.Permission_permission_disabled__2g5yi {
    cursor: not-allowed;
    background-color: #dbdada;
    pointer-events: none;
    opacity: 0.7;
    border-radius: 10px;
  }
.list_img{
    background-color: #EFF5F6;
    padding: 2%;
    border-radius: 50px;
    margin-left: 5%;
}
.pv-block {
    justify-content: center;
    border-radius: 20px;
    border: 1px solid pink;
    background-color: whitesmoke;
    margin: auto;
    height: auto;
    width: 60%;
    margin-bottom: 2%;
}

.pv-block-header {
    display: flex;
    flex-direction: row;
    background-color: #13697E;
    color: white;
    margin-top: 0%;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    height: 25%;
    padding: 4%;
}

.pv-block-header>.hhid {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.pv-block-header>.hhid>.hh-name {
    font-size: 18px;
    font-weight: bold;
}

.pv-block-header>.hhid>.hh-id {
    font-size: 16px;
}

.pv-block-header > .buttons{
    display: flex;
    flex-direction: row;
    padding: 2%;
}

.pv-block-header > .buttons > .seloptionDiv{
    margin-right: 10px;
}



.pv-block-body {
    display: flex;
    flex-direction: column;

}

.pv-block-body>.coll-block {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 3%;
    font-size: 18px;
}


.pv-block-body>.coll-block>.coll-date .coll-mode .coll-dt {
    display: flex;
    flex-direction: column;
}

.coll-details-data {
    font-weight: bold;
}

.coll-block-edit{

    margin: auto;
    margin-top: 2%;
    text-align: center;
    background-color: whitesmoke;
    height: 90px;
    display: flex;
    flex-direction: column;
    width: 98%;
}

.coll-block-edit >.coll-block-edit-input {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 1%;
}

.coll-details-data-amt {
    background-color: aqua;
    border-radius: 10px;
    width: 20%;
    margin: auto;
    font-weight: bold;
}

.pv-block-error-msg {
    border: 2px solid;
    border-radius: 20px;
    margin: auto;
    margin-top: 8%;
    text-align: center;
    background-color: whitesmoke;
    height: 90px;
    display: flex;
    flex-direction: column;
    padding-top: 2%;
    width: 50%;
}

.err-block-header {
    font-weight: bold;
    font-size: 18px;
}

.updateButtonDiv{
    margin-top: 2%;
}

@media(max-width:407px) {
    .pv-block {
        justify-content: center;
        border-radius: 20px;
        border: 1px solid pink;
        background-color: whitesmoke;
        margin: auto;
        height: auto;
        width: 100%;

    }

    .pv-block-header {
        display: flex;
        flex-direction: row;
        background-color: #13697E;
        color: white;
        margin-top: 0%;
        border-radius: 20px 20px 0 0;
        justify-content: center;
        height: 25%;
        padding: 2%;
    }

    .pv-block-header>.hhid {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-left: 2%;
    }

    .pv-block-header>.hhid>.hh-name {
        font-size: 14px;
        font-weight: bold;
    }

    .pv-block-header>.hhid>.hh-id {
        font-size: 12px;
    
    }

    .pv-block-header>.buttons {
        width: 40%;
        display: flex;
        flex-direction: column;
        
    }

    
    .coll-date, .coll-mode, .coll-dt{
        font-size: 12px;
    }
    
}
.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item img {
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;  
  }
  .react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.dropping {
    visibility: hidden;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
  
  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }
  
  .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  
body {
  padding: 20px;
}

#content {
  width: 100%;
}

.react-grid-layout {
  display: flex;
  flex-direction: row;
  background-color: #13697E;
  color: white;
  margin-top: 2%;
  border-radius: 20px 20px 0 0;
  /* justify-content: center; */
  /* height: 40%; */
  /* padding: 4%; */
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  /* background: #ccc; */
  /* border: 1px solid black; */
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
  background: #cce;
}

.headerText{
  /* font-size: 18px;
  text-align: left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
  cursor: pointer; */
  color: white;
}

.mobbody{
  color: black;
}
.bodytext{
  font-size: auto;
  /* text-align: center; */
  /* position: absolute; */
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  /* margin: auto; */
  height: 24px;
  /* cursor: pointer; */
  color: black;
}

.bodytext span {
  color: black;
  overflow-wrap: break-word;
}

.payload_view_table_actions {
  /* margin-top: 4%; */
}

.payload_view_table_actions button {
height: 37px;
margin: 5px;
width: 40px;
}

.react-grid-item .text {
  font-size: auto;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
  cursor: pointer;
  color: black;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}

.toolbox__items {
  display: block;
}

.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}


/* added by vaibhav */

.pv-block-body-dd {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  margin-top: 0%;
  border-radius: 0;
} 

.pv-block-body-dd input{
  color: black;
  /* border-radius: 0 0 20px 20px; */
} 


.pv-block-body {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  margin-top: 0%;
  border-radius: 0 0 20px 20px;
  margin: auto;
  padding: 3%;
} 

.pv-block-error-msg {
  font-size: 14px;
  border: 2px solid;
  border-radius: 20px;
  /* margin: auto; */
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
  background-color: whitesmoke;
  height: 60px;
  display: flex;
  flex-direction: column;
  /* padding-top: 2%; */
  /* width: 50%; */
}

body {
  font-family: 'MyCustomFont', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem; /* Scales better for accessibility */
  background-color: #fafafa;
  width: 100%;
  height: 100%;
}

body {
  font-family: 'MyCustomFont', sans-serif;
}

.no-container {
  padding: 0;
}

.load-btn {
  background-color: #12B4D1;
  color: #ffffff;
  font-weight: 700;
}

html {
  width: 100%;
  height: 100%;
}

.main-sec {
  width: 100%;
}

/* .fix-width {
  max-width: 450px;
  
} */
img.view-pass {
  float: right;
  margin-right: 10px;
  margin-top: -50px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.logo-area {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.logo-area img {
  width: 35%;
  /* margin-top: 5%; */
  padding: 5%;
}

.ml-32 {
  margin-left: 25%;
}

.login-text {
  margin-top: 5%;
  margin-bottom: 5%;
}

.login-title h2 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}

.login-subtitle {
  font-size: 15px;
  font-weight: 500;
}

.login-form-holder {
  align-items: center;
}

.login-form-holder ul {
  padding: 10px;
  background: #EFF5F6;
  border: none;
  border-radius: 50px;
}

.login-form-holder ul li.nav-item {
  width: 48%;
  text-align: center;
}

/*.login-form-holder ul li.nav-item:active {
  width: 48%;
  text-align: center;
  border-radius: 50px;
  margin: 1%;
  background: #FFFFFF;
}*/
.login-form-holder ul li.nav-item a {
  color: #818080;
  padding: 3%;
  font-size: 16px;
  /*20px;*/
  font-weight: 500;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-radius: 50px;
  margin: 1%;
  background-color: #FFFFFF;
  color: #010101;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.nav-pills a.active {
  border-radius: 50px;
  margin: 1%;
  background-color: #FFFFFF;
  color: #010101;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.email-login {
  margin-top: 10%;
  margin-bottom: 15%;
}

.email-label {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5%;
}

.email-box {
  border-radius: 50px;
  padding: 1rem 0.75rem;
}

.password-box {
  border-radius: 50px;
  padding: 1rem 0.75rem;
  width: 100%;
  float: left;
}

.email-submit {
  width: 100%;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 2%;
}

.ortext {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding: 3%;
}

.otp-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 15%;
  padding: 2%;
}


/*------------------- Toggle Switch ----------------------*/
.toggle_switch {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggler-div {
  margin: 5px 20px 20px;
  text-align: center;
  align-items: center;
}

.checkbox-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 30px;
  background: #EFF5F6;
  display: block;
  border-radius: 100px;
  position: relative;
  /* border: 1px solid #ced4da; */
}

.checkbox-label:after {
  content: '';
  position: absolute;
  top: -1px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: #1FA1C1;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+.checkbox-label:after {
  left: calc(100% - 0px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

/*  */

.dashboard-card-holder {
  align-items: center;
}

.user_icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background-color: #1FA1C1; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_icon img {
  width: 80%;
  height: 80%;
}

.card-border-small {
  border-radius: 20px;
  box-shadow: 0px 5px 6px #e1e1e1;
  width: 46%;
  float: left;
  margin: 2% 2%;
  height: 160px;
  /* padding: 5%; */
  cursor: pointer;
}

.card-border-small-report {
  border-radius: 20px;
  box-shadow: 0px 5px 6px #e1e1e1;
  width: 46%;
  float: left;
  margin: 2% 2%;
  height: 160px;
  /* padding: 5%; */
  cursor: pointer;
  border-bottom: 19px solid #23BFBA;
}

.report-card-header {
  padding: 0.5rem 0.12rem;
  margin-bottom: 0;
  background-color: #23BFBA;
  border-radius: 10px 10px 0 0;
}

.report-card-header h2 {
  font-size: 15px;
  font-weight: 500;
  min-width: -webkit-max-content;
  min-width: max-content;
  display: contents;
  color: #ffffff;
  font-weight: 600;
}

/* .report-card-bottom {
  color: #23BFBA;
  border-bottom: 12px solid;
  border-radius: 0 0 10px 10px;
} */

.card img {
  width: 60px;
}

.card-header h2 {
  font-size: 17px;
  font-weight: 500;
  min-width: -webkit-max-content;
  min-width: max-content;
  display: contents;
}

.bgw {
  background: #ffffff;
  border-radius: 0 0 20px 20px;
}
.hideimg {
  visibility: hidden;
}

.image-holder {
  text-align: center;
}

.image-holder img {
  background-color: #41D9D0;
}

.email-error {
  color: red;
  font-size: 14px;
  margin-top: 2%;
  margin-left: 5%;
}

/* Dashboard Start */
.image-holder-22 {

  text-align: center;

}

.image-holder-22 img {

  border-radius: 100px;

  padding: 10px;

}

.dashboard-card-holder {
  align-items: center;
  justify-content: space-around;
  /* margin-top: 10%; */
}

.card-border {
  border-radius: 20px;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.image-holder {
  text-align: center;
}

.image-holder img {
  background-color: #41D9D0;
  border-radius: 100px;
  padding: 10px;
}

.card-heading,
.card-subheading {
  margin-top: 5%;
  text-align: center;
}

.card-body {
  padding: 2%;
  padding-top: 20px;
  padding-bottom: 10px;
}

.card-heading h2 {
  font-size: 17px;
  font-weight: 500;
  min-width: -webkit-max-content;
  min-width: max-content;
  display: contents;
}

.card-subheading h2 {
  font-size: 17px;
  font-weight: 400;
}

.image-holder-2 {
  text-align: center;
  width: 80px;
}

.image-holder-2:hover {
  -webkit-transform: translate(0, -0.5em);
          transform: translate(0, -0.5em);
}

.image-holder-2 img {
  /* background-color: #0178B7; */
  border-radius: 100px;
  /* padding: 10px; */
  width: 80px;
}

.image-holder-2 h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5%;
}

.image-report-2 {
  text-align: center;
  width: 200px;
}

.image-report-2:hover {
  -webkit-transform: translate(0, -0.5em);
          transform: translate(0, -0.5em);
}

.image-report-2 img {
  /* background-color: #0178B7; */
  border-radius: 100px;
  /* padding: 10px; */
  width: 60px;
}

.image-report-2 h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-holder-3 {
  text-align: center;
}

.image-holder-3 img {
  background-color: #D49378;
  border-radius: 100px;
  padding: 10px;
}

.image-holder-4 {
  text-align: center;
}

.image-holder-4 img {
  background-color: #5FB270;
  border-radius: 100px;
  padding: 10px;
}

.image-holder-5 {
  text-align: center;
}

.image-holder-5 img {
  background-color: #191C78;
  border-radius: 100px;
  padding: 10px;
}

.image-holder-app {
  text-align: center;
}

.image-holder-app img {
  background-color: #ffffff;
  border-radius: 100px;
  padding: 10px;
}

.image-holder-6 {
  background-color: #53AB11;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.image-holder-6 img {
  width: 70%;
}

.image-holder-7 {
  background-color: #0178B7;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.image-holder-7 img {
  width: 70%;
}

/* Dashboard Start */
/* OTP Verify Start */
.otp {
  margin-bottom: 63px;
}

.otp-form {
  text-align: center;
}

.title {
  margin: 0 auto;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
}

.title_text {
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 39px;
}

.mob_number {
  margin: 0 auto 63px;
  font-size: 26;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}

.icon_pen {
  text-align: center;
  background-color: #1FA1C1;
  border-radius: 50%;
  padding-top: 1%;
  height: 35px;
  width: 35px;
}

.opt-container {
  margin-left: 41px;
  margin-right: 40px;
}

.otp {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #090909;
  font-size: 25px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #EFF5F6;
  outline: none;
  border: 0;
  margin: 0 8px 67px 8px;
}

.btn_css {
  width: 85%;
  background: #1FA1C1;
  margin: 0 25px 46px 26px;
  height: 55px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 3px 6px 0px rgba(76, 167, 183, 1);
}

.images {
  margin-top: 26px;
  margin-bottom: 53px;
}

.user {
  height: 229px;
  width: 203px;
  margin: 0 auto;
}

/* OTP Verify End */
/* Reportd */
.header-area {
  margin: 5% 0 5% 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menu-image-holder {
  background: #EFF5F6;
  padding: 3%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  float: left;
  justify-content: center;
}

img.menu-image {
  width: 25px;
}

.head-title {
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
  color: #000000;
}

.menu-image-holder2 {
  background: #EFF5F6;
  padding: 2%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  float: left;
  justify-content: center;
  margin: 1%;
}

img.menu-image2 {
  width: 33px;
}

.menu-image-holder3 {
  background: #EFF5F6;
  padding: 2%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  float: left;
  justify-content: center;
  margin: 1%;
}

img.menu-image3 {
  width: 26px;
}

.main-area {
  background: #EFF5F6;
  border-radius: 1px 30px 30px 1px;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.title-holder h2 {
  font-size: 19px;
  color: #1FA1C1;
  /* margin: 2%;
  background-color: #EFF5F6;
  padding: 3%;
  border-radius: 15px; */
}

.title-holder_bg {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2%;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 5%;
}

.title-holder_bg div {
  display: flex;
  align-items: center;
}

.title-holder_bg h2 {
  font-size: 19px;
  color: black;
  margin: 2%;
  display: inline;
}

.title-holder_bg .log-out {
  /* margin: 5% 0 5% 0; */
  margin-left: 2%;
}

.name-holder {
  padding: 5% 0;
}

.name-holder h2 {
  font-size: 17px;
  font-weight: 600;
  padding: 5%;
  background: #1FA1C1;
  border-radius: 1px 50px 50px 1px;
  color: #FFFFFF;
  float: left;
  text-transform: capitalize;
}

.demand-holder {
  text-align: inherit;
  padding-left: 60%;
}

.demand-holder h2 {
  font-size: 16px;
  font-weight: 600;
  color: #1FA1C1;
}

.demand-holder span {
  font-size: 16px;
  font-weight: 500;
}

.main-foor-part {
  padding: 1%;
}

.collected-holder h2 {
  font-size: 16px;
  width: 50%;
  font-weight: 500;
  color: #49BB43;
}

.collected-holder {
  margin-left: 7%;
  width: 40%;
  position: relative;
  display: inline-block;
  ;
}

.collected-holder span {
  font-size: 16px;
  font-weight: 500;
}

.balance-holder {
  width: 50%;
  display: inline-block;
  position: relative;
  padding-left: 13%;
}

.balance-holder h2 {
  font-size: 16px;
  font-weight: 500;
  width: 50%;
  text-align: inherits;
  color: #D9724A;
}

.balance-holder span {
  font-size: 16px;
  font-weight: 500;
}

.report-card-title h3 {
  background-color: #d7dedf;
  width: 70%;
  padding: 3%;
  margin: auto;
  border-radius: 100px;
  font-size: 17px;
  font-weight: 500;
}

.report-card-title {
  text-align: center;
}

.report-card-subhead {
  border-bottom: 1px dashed #A7A3A3;
  display: flex;
  padding: 4px;
  align-items: center;
}

span.subhead {
  color: #1FA1C1;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
}

span.subamount {
  font-size: 17px;
  font-weight: 600;
}

span.subheadone {
  color: #49BB43;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
}

span.subheadthree {
  color: #D9724A;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
}

.report-card-holder {
  align-items: center;
  margin-top: 21px;
  width: 100%;
  float: right;
  cursor: pointer;
}

/* Reportd End */
/* CSO */
.icon_bg {
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: medium;
}

.icon_notification {
  background-color: rgba(255, 0, 0, 0.6);
  color: white;
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 0.7em;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: fadeInAnimation ease 1s;
          animation: fadeInAnimation ease 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icon_bg:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.icon_bg:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

/* 
.icon_bg {
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  display: inline-block;
  padding: 10;
  cursor: pointer;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
} */

.icon_bg .icon_img {
  height: 50%;
  width: 50%;
  /* margin-top: 25%;
  margin-left: 25%; */
}

.icon_bg .cso_icon_img {
  height: 50%;
  width: 50%;
}

.header_text {
  font-size: 18px;
  line-height: 27px;
  padding: auto;
  margin-top: 3%;
  margin-right: 5%;
}

.top_nav {
  margin: 5% 3% 5% 3%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.filter_button_container {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  padding: 10px;
  border-radius: 8px;
  color: white;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

.sdiv {
  margin-left: 5%;
  margin-bottom: 5%;
}

.stext {
  color: #1FA1C1;
  font-size: 19px;

}

.cname_block_csodemand {
  margin: 5% 5% 25% 0%;

}

.cust_name {
  background-color: #1FA1C1;
  color: white;
  height: 70px;
  width: 278px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  padding: 10px;
  float: left;
  display: flex;
  align-items: center;
}

.cname {
  text-align: center;
  font-size: 21px;
}

.nach_block {
  float: right;
  background-color: #D2F9D0;
  color: #5BC256;
  height: 58px;
  width: 103px;
  border-radius: 25px;
  padding-top: 12px;
  padding-left: 6%;
}

.nach_text {
  text-align: center;
  font-size: 19px;
}

.info_box {
  background-color: white;
  border-radius: 15px;
  padding: 3%;
  width: auto;
  box-shadow: 2px 2px 3px 3px #f1efef;
  text-align: -webkit-center;
}

.cname_loan_info_block {
  background-color: #EFF5F6;
  height: auto;
  width: 95%;
  padding: 10px;
  margin-right: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.left_side {
  float: left;
}

.left_side>.row {
  padding-top: 5%;
}

.right_side {
  float: right;
  margin-top: 5%;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
}

.name_cds {
  font-size: 19px;
  font-weight: 500;
  text-transform: capitalize;
}

.nach-img {
  width: 30px;
  height: 30px;
  flex-shrink: 0;

}

.loan_id {
  color: #1FA1C1;
  font-size: 18px;
}

.charges_block {
  width: 100%;
  /* background-color: red; */
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.charges_desc {
  width: 40%;
}

.charges {
  text-align: right;
  width: 40%;
}

.separator {
  border-bottom: 1px dashed black;
  width: 90%;
  margin-top: 5%;
  margin-bottom: 5%;
  overflow: hidden;
}

.col_bal_block {
  margin-top: 10%;
  /* background-color: yellow; */
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.collected {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  color: #49BB43;
}

.balanced {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  color: #D9724A;
}

/* .col_block {
  margin-left: 5%;
  padding-top: 8%;
  margin-top: 5%;
  text-align: center;
  background-color: #D2F9D0;
  width: 155px;
  height: 53px;
  border-radius: 15px;
  color: black;
  font-size: 17px;
  margin-bottom: 10%;
} */


.tot_fail_block {
  background-color: #EFF5F6;
  height: auto;
  width: 95%;
  border-radius: 15px;
  /* display: flex; */
  justify-content: space-around;
  text-align: center;
  font-size: 17px;
  padding-bottom: 10px;
  margin: 2%;
  /* padding-top: 8%; */
}

.foot-label {
  padding: 2%;
  text-align: left;
  margin-left: 8%;
  font-size: 20px;
  font-weight: 500;
  color: #4BAE4F;
}

.tf_right_side {
  width: 50%;
}

.tf_left_side {
  width: 50%;
}

/* CSO END */
/* CSO Transaction History */
span.foot-img-holder-reject {
  background-color: #D41616;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-reject img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-finopsverify {
  background-color: #39a3c6;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-finopsverify img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-approve {
  background-color: #2D9A12;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-approve img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-reject {
  background-color: #d10e0e;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-reject img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-submit {
  background-color: #3BE911;
  display: inline-block;
  width: 25px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-submit img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-save {
  background-color: #2C22BA;
  display: inline-block;
  width: 25px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-save img {
  width: 13px;
  height: 15px;
}

.trans_back_icon_bg {
  background-color: #EFF5F6;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 3%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.trans_back_icon_bg:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.trans_back_icon_bg:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

img.back_icon_img {
  height: 50%;
  width: 50%;
}

.transaction-head-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 19px;
  font-weight: 500;
  color: black;
  margin-bottom: 20px;
}


.month-drop {
  width: 34%;
  float: left;
}

.category-drop {
  width: 33%;
  float: left;

}

.filter-drop {
  width: 33%;
  float: right;
}

.select-data {
  border-radius: 100px;
  padding: 5px 9px;
  font-size: 15px;
  height: 44px;
  width: 98%;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.16);
}



.card-image-holder {
  background-color: #39A3C6;
  /* width: 15%; */
  border-radius: 10px;
  margin-left: 5%;
  float: left;
}

.card-image-holder img {
  width: 55px;
}

.card-text {
  width: 48%;
  float: left;
  margin-left: 5%;
}

.card-text h3 {
  font-size: 18px;
  text-transform: capitalize;
}

.card-amount-area {
  display: inline-flex;
  /* background-color: #EFF5F6; */
  width: 100%;
  /* padding: 3%; */
  color: #20A1C1;
  border-radius: 10px;
  margin-top: 4%;
}

.card-amount-area span {
  font-size: 20px;
  font-weight: 500;
}

span.card-amt {
  width: 70%;
  text-align: end;
}

.card-foot-area {
  padding: 2%;
  margin-top: 3%;
  font-size: 15px;
  font-weight: 300;
  color: #A7A6A6;
}

.date-time-area {
  width: 50%;
  float: left;
  color: #343434;
}

.status-area img {
  width: 5px;
  height: 20px;
}

.status-area {
  text-align: end;
  font-weight: 440;
  color: #343434;
}

span.foot-img-holder {
  background-color: #F52B2B;
  display: inline-block;
  width: 24px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-success {
  background-color: #37AB1B;
  display: inline-block;
  width: 24px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

.card-text h2 {
  font-size: 20px;
}

.transaction-card-holder {
  width: 100%;
}

.transaction-header-area {
  margin: 5% 0 0 0;
  height: 70px;
}

.reversal_history {
  list-style: none;
  background-color: lightblue;
  padding-left: 0;
  padding: 15px;
  border-radius: 15px;
  margin: 4px 10px;
  font-size: 0.95rem;
  font-weight: 500;
  position: relative;
  margin-top: 20px;
}

.reversal_history::before {
  content: '';
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: absolute;
  top: 0;
  left: 10%;
  width: 30px;
  height: 23px;
  background-color: inherit;
  -webkit-transform: translateY(-70%);
          transform: translateY(-70%);
}

.reversal_history li {
  margin-top: 5px;
}

.reversal_history_header_img {
  max-width: 50px;
  background-color: #39A3C6;
  padding: 6px;
  border-radius: 10px;
}

.reversal_history_amount {
  margin-right: auto;
  list-style: none;
  padding-left: 15px;
  width: 100%;
}

.reversal_history_amount li {
  display: flex;
  justify-content: space-between;
}

.reversal_history_status {
  display: flex;
  align-items: center;
}

.reversal_history_status span {
  font-weight: 500;
  color: #444242;
  font-size: 0.9rem;
}

.reversal_history_status img {
  width: 19px;
  padding: 3px;
  border-radius: 50%;
  margin-left: 8px;
}

.reversal_history_button {
  margin-left: 10px;
  border-radius: 5px;
  color: white;
  background-color: #1FA1C2;
  font-size: 1.1rem;
  padding: 0.2em 0;
  cursor: pointer;
  border: none;
  outline: none;
  width: 165px;
  height: 50px;
}

.reversal_history_button:hover {
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
}

.reversal_history_button:focus {
  border: none;
  outline: 4px solid rgba(76, 167, 183, 0.3);
}

.reversal_history_last_updated {
  font-weight: 300;
  font-size: 0.9rem;

}

/* CSO Transaction History End */


/* Search Household Start */

.bluetext-header-sort {
  font-size: 17px;
  color: #1FA1C1;
  /* font-weight: bold; */
  margin-left: 5%;
  margin-bottom: 5%;
}

.top_nav {
  margin: 5% 0 5% 0;
  display: flex;
  justify-content: space-around;
}

/*AT-2335 ||Rohit Chitampalle ||23-08-2023 ||change alignment*/
.top_nav_report {
  margin: 0 0 0 0;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(45deg, #41D8D1, #01058B);
  padding-top: 2%;
}

/* a>.icon_bg {
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* 
a>.icon_bg:hover{
  box-shadow: none;
}

a>.icon_img {
  height: 24px;
  width: 24px;
} */

.tot_amt_block {
  margin: auto;
  /* height: 172px; */
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  text-align: center;

}

.amt_b {
  width: 32%;
  max-width: 130px;
}

.blue_block {
  background-color: rgba(31, 161, 193, 0.16);
  height: 93px;
  width: 100%;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 20%;
  margin-bottom: 5%;

  color: #1FA1C1;
}

.bluetext {
  color: #1FA1C1;
  /* padding-top: 30%; */
}

.green_block {
  background-color: #9BFF95;
  height: 93px;
  width: 100%;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 20%;
  margin-bottom: 5%;
  color: #2F5D2C;
}


.red_block {
  background-color: #FFBEA5;
  height: 93px;
  width: 100%;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 20%;
  margin-bottom: 5%;
  color: #8A472D;
}

.hh_blue_block {
  background-color: #EFF5F6;
  width: 100%;
  /* height: 310px; */
  margin: auto;
  margin-top: 5%;
  border-radius: 25px;
  box-shadow: 1px 1px 2px 2px #cacaca;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hh_white_block {
  background-color: white;
  width: 100%;
  /* height: 310px; */
  margin: auto;
  margin-top: 2%;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* padding-bottom: 7%; */
  position: relative;
}

.name_hh_block {
  /* background-color: red; */
  padding: 1% 2%;
  margin: 10px;
  margin-bottom: 5px;
  display: flex;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
  background-color: #EFF5F6;
}

.name {
  font-size: 16px;
  text-transform: capitalize !important;
  font-weight: 600;
}

.branch_bluetext {
  font-size: 17px;
  color: #1FA1C1;
}

.name img {
  width: 40px;
}
.filter_holder {
  display: flex;
  justify-content: space-between;
}

.hh_id {
  color: #000000;
  font-size: 17px;
  padding-left: 5%;
}

.amt_details_block {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.amt_details_block2 {
  /* display: flex;
  justify-content: space-around; */
  text-align: center;
}

.dem_block {
  background-color: #1FA1C121;
  /* width: 155px;
  height: 53px; */
  margin-top: 5%;
  padding: 8%;
  color: black;
  border-radius: 15px;
}

.col_block {
  background-color: #2CE22321;
  /* width: 155px;
  height: 53px; */
  color: black;
  border-radius: 15px;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8%;
}

.amt_bal_block {
  margin-top: 2%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  position: relative;
}

.upload_icon_bg {
  all: unset;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1FA1C1;
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 5px;
}

.upload_icon_img {
  width: 50%;
}

.bal_block {
  background-color: #D9724A12;
  /* width: 155px;
  height: 53px; */
  display: flex;
  justify-content: center;
  margin-top: 5%;
  align-items: center;
  color: black;
  border-radius: 15px;
  padding: 8%;
}

.search_block {
  background-color: white;
  border-radius: 25px;
  height: auto;
  margin: auto;
  padding-bottom: 3%;
  margin-top: 10%;
  padding-top: 3%;
}


.sort_block {
  background-color: white;
  border-radius: 25px;
  height: auto;
  margin: auto;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: 2%;
}

.search_elements {
  margin-top: 5%;
}

.search_elements1 {
  margin-top: 3%;
  padding: 15px 10px;
  line-height: 10px;
}

.rinput1 {
  border-radius: 50px;
}

.rinput {
  border-radius: 25px;
}

.rbtn {
  border-radius: 25px;
}

.sortBlueBox {
  width: 100%;
  height: 53px;
  margin: 0 auto;
  background-color: #1FA1C121;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.bluetext-header {
  font-size: 17px;
  color: #1FA1C1;
}

.unique-names {

  overflow: scroll;
  overflow-x: hidden;
  height: 80%;
}



.demDatalist {
  display: flex;
  justify-content: space-between;
  color: red;
  width: 50%;
}

input[type="range"] {
  width: 50%;
}

.unique-name-click {
  cursor: pointer;
}

.reference {
  display: flex;
  justify-content: space-around;
  padding-left: 5%;
}

.refInput {
  width: 90%;
  padding-right: 5%;
}

.refUpload {
  width: 20%;
}

/* Search Household End */
/* Filter window start */
.filter_window {
  /* width: 369px; */
  height: auto;
}

.filter_header {
  float: left;
  width: 44%;
  height: inherit;
  background-color: #EFF5F6;
}

.filter_amt {
  float: right;
  height: inherit;
  width: 56%;
  /* box-shadow: 1px 1px 2px 2px #cacaca; */
}

.filter-table {
  font-size: 17px;
}

.main-sec {
  width: 100%;
}

/* .fix-width {
  max-width: 450px;
  
} */

.filter-btn-div {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  bottom: 4%;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* Filter window end */
/* Collection receipt start */
.hhid_holder {
  background-color: #EFF5F6;
  padding: 1%;
}

.cr_msg_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-top: 50%;
}

.modal-btn-block {
  display: flex;
  justify-content: space-around;
}

.modal-btn-block button {
  width: 40%;
}


.cr_top_nav {
  margin: 5% 0% 5% 0%;
  height: 70px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10%;
  align-items: center;
}



.navadhan_logo_block {
  display: flex;
  border-style: none;
  background-color: #EFF5F6;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 81px;
}



.acen_logo_block {
  display: flex;
  border-style: none;
  background-color: #EFF5F6;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 81px;
}



/* .collection-receipt-block {
  
   background-color: white;
  
   box-shadow: 2px 2px 3px 3px #cacaca;
  
   width: 402px;
  
   height: 880px;
  
   border-radius: 25px;
  
   margin: auto;
  
   padding-top: 5%;
  
  } */



.collection_block_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.bluetext {
  color: #20A1C1;
  font-size: 16px;
}



.htext2 {
  margin: 2% 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 20px;
  font-weight: 600;
}



.rinput {

  border-radius: 10px;

  /* margin-bottom: 3%; */

}



.loan_disbursement_block {
  background-color: #EFF5F6;
  border-radius: 15px;
  text-align: -webkit-center;
  margin: auto;
  box-shadow: 1px 1px 2px 2px #cacaca;
  color: black;
  font-size: 20px;
  padding: 3%;
  width: 100%;
}



.cname_block_pt {
  width: 100%;
  height: 54px;
  border-radius: 15px;
  margin-top: 1%;
  padding: 2%;
  background-color: #0DBEEB10;
  display: flex;
  justify-content: start;
  align-items: center;
}



.amt_date_block {

  display: flex;

  justify-content: space-around;

  text-align: center;

  margin-top: 3%;

  font-size: 20px;

}



.overdue_demand_block {
  background-color: #EFF5F6;
  border-radius: 15px;
  width: 100%;
  margin: auto;
  box-shadow: 1px 1px 2px 2px #cacaca;
  color: black;
  font-size: 20px;
  padding: 3%;
}



.cust_name_pt {
  font-size: 18px;
  /*width: 100%;*/
}



.call_icon {

  float: right;

  width: 20%;

}


.dis_block {

  /* background-color: white; */

  border-radius: 15px;

  width: 140px;

  height: 54px;

  /* box-shadow: 1px 1px 2px 2px #cacaca; */

  margin-top: 5%;

  padding-top: 8%;

  font-size: 18px;

}



.total_amt_block {
  display: block;
  margin-top: 4%;
  text-align: -webkit-center;

}



.tamt {

  margin-top: 3%;

  font-size: 20px;

}



/* .new_amt_block {
  
   display: flex;
  
   justify-content: space-around;
  
  } */



.amt_block {
  background-color: #EFF5F6;
  padding: 2%;
  border-radius: 15px;
  text-align: center;
  font-size: 19px;
}



.button_block {

  display: flex;

  justify-content: space-around;

  margin-top: 5%;

  margin-bottom: 5%;

  font-size: 20px;

}



/* Collection receipt end */

/* Payment Transfer Start */
.mymodal {
  display: block !important;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}

.errmsg {
  color: red;
  font-size: 14px;
  margin-top: 2%;
}

.emi-left {
  font-size: 15px;
  float: left;
  margin-left: 3%;
}

.emi-right {
  font-size: 15px;
  float: right;
}

.payment-method-block {
  background-color: white;
  box-shadow: 2px 2px 3px 3px #cacaca;
  width: 100%;
  height: auto;
  border-radius: 25px;
  margin: auto;
  padding: 2%;
}

.htext {
  margin: 1% 5%;
  font-size: 20px;
}

.pthtext2 {
  /* margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 3%; */
  /*margin: 2% 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 20px; */
  margin: 1% 3%;
  padding-bottom: 1%;
  font-size: 20px;
}

.poptions {
  display: flex;
  justify-content: space-around;
  margin: 2% 2%;
  padding: 1%;
  font-size: 17px;
}

.pt_total_amt_block {
  display: flex;
  justify-content: space-around;
  margin-top: 6%;

}

.pt_tamt {
  margin-top: 3%;
  font-size: 20px;
}

.pt_amt_block {
  background-color: #EFF5F6;
  border-radius: 25px;
  text-align: center;
  padding: 3%;
  font-size: 19px;
}

.pt_button_block {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 20px;
  width: 100%;
}

.pt-confirm-box {
  border-radius: 15px;
  padding: 3%;
}

.cb-cname {
  font-size: 16px;
  color: #1FA1C1;
  margin: 2% 0% 2% 3%;
}

.cb-cname-box {
  /* width: 344px;
  height: 53px; */
  border-radius: 15px;
  background-color: #1FA1C121;
  /* padding-top: 4%;
  padding-left: 4%; */
  font-size: 17px;
  margin-bottom: 3%;
  margin-left: 2%;
  padding: 2%;
}

.flipped {
  -webkit-transform: rotateY(180deg) !important;
          transform: rotateY(180deg) !important;
}

.back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.emiCharge {
  /* display: flex; */
  justify-content: space-around;

}

.emi-amt-box {
  width: 165px;
  height: 53px;
  background-color: #1FA1C121;
  border-radius: 15px;
  padding-left: 8%;
  padding-top: 8%;
}

.cb-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
}

/* Payment Transfer End */
/*cash acceptance denom main starts here*/
.amount-holder {
  width: 100%;
}

.ca-header-box {
  margin-top: 29px;
  width: 100%;
  height: auto;
  /*163px;*/
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  font-size: 20px;
  color: #20A1C1;
  padding: 10px;
}

.remarks-inp {

  width: 70%;

  height: 40px;

  border-radius: 10px;

  border: 1px solid grey;

  outline: none;

}

.ca-header-txt-box {
  width: 100%;
  min-height: 58px;
  background-color: #EFF5F6;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 45px;
}

.filter-options-container-main {
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  color: white;
}


.filter-options-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 10px;
  height: auto;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}


.filter-options-container a span {
  font-size: 15px;
  color: #010101;
}

.filter-options-container a img {
  width: 14px;
  height: 14px;
}

.cso-list-container {
  width: 100%;
  height: auto;
}

.cso-card-container {
  margin-top: 29px;
  width: 100%;
  min-height: 185px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
}

.card-header-container {
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: #EFF5F6;
  border-radius: 12px;
}

.card-header-container span {
  font-size: 20px;
  color: #20A1C1;
  max-width: 170px;
}

.card-header-container .date-time span {
  color: #818485;
  font-size: 17px;
}

.amt-container {
  min-width: 50%;
  height: 80px;
  margin-top: 13px;
}

.amt-container p {
  font-size: 18px;
  font-family: Montserrat;
  color: #010101;
}

.amt-container .bal-amount {
  width: 160px;
  height: 50px;
  padding: 15px;
  background-color: rgba(44, 226, 35, 0.12);
  border-radius: 10px;
  color: #15AE10;
  font-size: 18px;
}

.amt-container .hnd-amount {
  width: 160px;
  height: 50px;
  padding: 15px;
  background-color: #F8EEEA;
  border-radius: 10px;
  color: #D57551;
  font-size: 18px;
}

.sort_block_2 {
  background-color: white;
  border-radius: 25px;
  height: auto;
  margin: auto;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: 2%;
}

.filter_window_1 {
  height: 500px;
}

.month-filter {
  overflow: auto;
}

/*cash acceptance denom main end here*/
/*cash acceptance denom starts here*/
textarea.remarks-inp {
  width: -webkit-fill-available;
  height: auto;
}

.ca-dn-card {
  margin-top: 29px;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ca-header-txt-box {
  color: #20A1C1;
}

.dn-container {
  width: 100%;
}

.dn-row {
  width: 100%;
  margin: 11px;
}

.dn-row a {
  cursor: pointer;
}

.dn-row-box {
  width: 100px;
  height: 45px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dn-header-text {
  color: #20A1C1;
  font-weight: 450;
  font-size: 18px;
  font-family: Montserrat;
}

.dn-gre-amt {
  background-color: rgba(44, 226, 35, 0.21);
  border-radius: 8px;
  font-size: 18px;
}

.dn-red-amt {
  background-color: rgba(217, 114, 74, 0.21);
  border-radius: 8px;
  font-size: 18px;

}

.dn-pri-amt {
  background-color: #EFF5F6;
  border-radius: 8px;
  font-size: 18px;
}

.dn-total {
  min-width: 100px;
  background-color: #EFF5F6;
  border-radius: 8px;
  height: 45px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fn-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fn-button img {
  width: 20px;
  height: 20px;
}

.ca-submit-btn {
  width: 188px;
  height: 59px;
  /* background-color: #1FA1C1; */
  border-radius: 47px;
  margin-top: 29px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call_icon_bg {
  /* all: unset; */
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4BAE4F;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.call_icon_bg:active {
  outline: 1px solid rgb(11, 222, 246);
  transition: all 0.3s ease-out;
}

.call_icon_img {
  width: 60% !important
}

.msg_icon_bg {
  all: unset;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D27D5C;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s ease-in;
}

.msg_icon_bg:active {
  outline: 1px solid rgb(11, 222, 246);
  transition: all 0.3s ease-out;
}

.msg_icon_img {
  width: 65% !important;
}

/*cash acceptance denom end here*/
/* Deposit Money Start */
.back_icon_bg {
  border-radius: 50%;
  height: 52px;
  width: 52px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFF5F6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.back_icon_bg:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.back_icon_bg:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.back_icon_img_dm {
  height: 24px;
  width: 24px;
}

.bluetext_1 {
  color: #20A1C1;
  font-size: 19px;
  font-weight: 500;
  /* margin-right: 100px; */
}

.bluetext_2 {
  color: #20A1C1;
  padding-top: 30%;
  font-size: 19px;
  font-weight: semiBold;
  font-weight: normal;
  text-align: center;
}

.bluetext_3 {
  color: #000000;
  font-size: 19px;
  font-weight: semiBold;
  font-weight: normal;
  text-align: center;
}

.collection_margin {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 5%;
  width: 100%;
}

.collection-receipt-block {
  background-color: white;
  box-shadow: 2px 2px 3px 3px #cacaca;
  width: 100%;
  height: auto;
  border-radius: 25px;
  padding: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cash_handover_main {
  margin-top: 3%;
  background-color: #EFF5F6;
  width: 95%;
  height: 64px;
  margin: 2%;
  border-radius: 5px;
  padding-right: 5%;
  padding-left: 5%;
  display: flex;
  align-items: center;
}

.cash_handover_margin {
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  /* padding-left :80px; */
  border-radius: 5px;
}

.mask {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px; /* Adjust height as necessary to cover the black bar */
  background-color: white;
  z-index: 10;  /* Ensure the white bar is on top of the iframe */
}

.cash_handover_header {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 19px;
  font-weight: semiBold;
  border-radius: 5px;
  /*width: 220px;
    height: 50px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;

}

.cash_details_block {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 40px;
}

.cash_block_1 {
  text-align: left;
  padding-top: 5%;
  font-size: 17px;
  font-weight: 400;
  width: 189px;
}

.amt_rs_block_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFF5F6;
  min-width: 114px;
  min-height: 59px;
  overflow: hidden;
  border-radius: 5px;
  font-size: 19px;
}

.amt_rs_block_11 {
  color: #48C4C4;
  font-size: 17px;
  font-weight: 500;
}

.cash_details_block_2 {
  display: flex;
  margin-top: 5%;
  justify-content: space-between;
  text-align: center;
}

.cash_block_2 {
  text-align: left;
  padding-top: 5%;
  font-size: 17px;
  font-weight: 400;
  width: 190px;

}

.amt_rs_block_22 {
  color: #EF8053;
  font-size: 17px;
  font-weight: 500;

}

.total_block {
  margin-top: 5%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;

}

/*for image margin and position*/
.img_margin_1 {
  margin-top: 9%;
}

.img_margin_2 {

  margin-top: 3%;

}

/*for image -circle*/
.top_nav_1 {
  margin: 5% 3% 5% 3%;
  display: flex;
  justify-content: space-around;
  padding-top: 3%;

}

.icon_img_1 {
  height: 24px;
  width: 24px;
  margin-left: 10%;
  margin-top: 10%;
}



.green_block_1 {
  background-color: #2CE22333;
  width: 100px;
  height: 45px;
  color: black;
  margin-top: 5%;
  padding-top: 8%;
  border-radius: 5px;
  text-align: center;
  border: none;
}

.red_block_1 {
  background-color: #DDD9724A;
  width: 90px;
  height: 45px;
  color: black;
  margin-top: 5px;
  padding-top: -4px;
  border-radius: 5px;
  text-align: center;
  border: none;
  margin-left: 1%;
}

.red_block_2 {
  background-color: #DDD9724A;
  width: 100px;
  height: 45px;
  color: black;
  margin-top: 5px;
  padding-top: -4px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid black;
}

.blue_block_1 {
  background-color: #EFF5F6;
  width: 110px;
  min-height: 45px;
  color: black;
  margin-top: 5%;
  padding-top: 8%;
  border-radius: 5px;
  text-align: center;
  border: none;
  margin-left: 1%;
  overflow: hidden;

}

.total_margin_2 {
  text-align: center;
  width: 100%;
}

.total_block_2 {
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;

}

.total_margin_3 {
  width: 100%;
}

.total_block_3 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;

}

.total_margin_4 {
  width: 100%;
  text-align: center;
}

.total_block_4 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;

}

.total_margin_5 {
  width: 100%;
  text-align: center;
}

.total_block_5 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;

}

.total_margin_6 {
  width: 100%;
  text-align: center;
}

.total_block_6 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;

}

.total_margin_7 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.total_block_7 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;


}

/*for giving space between top to bottom*/
.total_margin_8 {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

/*for moving space to forward (next to current block)*/
.total_block_8 {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex: 2 1;
}

.total_cash {
  font-size: 19px;
  font-weight: semiBold;
  border-radius: 100px;
  text-align: center;
  width: 220px;
  height: 50px;
  color: black;
  display: flex;
  align-items: center;

}

/*for save button*/
.button_block {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 100px;

}

/* for rounded button */
.rbtn {
  border-radius: 25px;

}

/* Deposit Money End */
/* Collection Success Start */

.back-icon {
  width: 30px;
  height: 30px;
}

.back-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #EFF5F6;
}


.header-message {
  font-family: Montserrat;
  font-size: 19px;
  font-weight: medium;
}

.date-time {
  font-family: Montserrat;
  color: #000000;
  width: 100%;
  margin-top: 10px;
}


.paid-to-card {
  background-color: white;
  border-radius: 20px;
  height: 189px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.card-box-header {
  height: 64px;
  background-color: #EFF5F6;
  border-radius: 12px;
  font-size: 19px;
  font-family: Montserrat;
  color: #20A1C1;
  font-weight: 500;
}

.customer-name-text {
  color: black;
  font-family: Montserrat;
  font-weight: semibold;
  font-size: 20px;
}

.amount-paid-text {
  color: #2DA7C5;
  font-weight: medium;
  font-size: 20px;
}

.transfer-details-card {
  background-color: white;
  border-radius: 20px;
  height: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.transaction-id-text {
  color: #838687;
  font-weight: medium;
  font-size: 18px;
}

.transaction-id {
  color: black;
  font-family: Montserrat;
  font-weight: semibold;
  font-size: 18px;
}

.copy-button {
  height: 51px;
  width: 95px;
  border-radius: 32px;
  background-color: #EFF5F6;
  font-size: 19px;
  font-family: Montserrat;
  color: #20A1C1;
  font-weight: semibold;
}

.debited-from-text {
  color: #838687;
  font-weight: medium;
  font-size: 18px;
}

.section-break-line {
  color: #A7A3A3;
  border-top: 1px dashed #A7A3A3;
}

.contact-card {
  background-color: white;
  border-radius: 20px;
  height: 65px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  font-size: 20px;
  font-family: Montserrat;
}

.account-number {
  color: black;
  font-weight: medium;
  font-size: 18px;
  font-family: Montserrat;
}

.utr-number {
  color: black;
  font-weight: medium;
  font-size: 18px;
  font-family: Montserrat;
}

.nav-icons-footer-text {
  color: #1FA1C1;
  font-size: 15;
  font-family: Montserrat;
}

.profile-icon {
  width: 71px;
  height: 71px;
  background-color: #2AB4F5;
  border-radius: 50%;
  font-size: 30px;
  font-family: Montserrat;
  color: white;
  font-weight: bold;
}


.bank-logo {
  width: 66px;
  height: 64px;
  background-color: #EFF5F6;
  border-radius: 12px;
}

.logo-box {
  width: 60px;
  height: 60px;
  background-color: #1FA1C1;
  border-radius: 16px;
}

/* Collection Success End */
/*Collection Success - Vaibhav Changes*/
.click_icon {
  cursor: pointer;
}

.message-box {
  background-color: white;
  border-radius: 25px;
  height: 150px;
  margin: auto;
  margin-top: 10%;
  padding-top: 5%;
}

.red_block_2 {
  background-color: #DDD9724A;
  width: 100px;
  height: 45px;
  color: black;
  margin-top: 5px;
  padding-top: -4px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid black;
}

/*Collection Success - Vaibhav Changes*/
/* Logout Start */
.icon_logout {
  float: right;
  display: none;
  /* display: inline-block; */
}

img.icon_logout_img {
  height: 40px;
  width: 40px;
  margin-left: 10%;
  margin-top: 6%;
  position: relative;
}

/* Logout End */
/* CashDepositeBank Start */
.list_img {
  background-color: #EFF5F6;
  padding: 2%;
  border-radius: 50px;
  margin-left: 5%;
}

span.cash-id {
  float: right;
}

/* .list_img_right {
  background-color: #EFF5F6;
  padding: 2%;
  border-radius: 50px;
  margin-right: 5%;
  float: right;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
} */

.card-label {
  width: 50%;
  display: inline-block;
  padding: 2%;
}

.card-amtlabel {
  float: right;
  width: 30%;
  text-align: center;
  background-color: #2CE22321;
  border-radius: 15px;
  padding: 2%;
}

.card-amtdep {
  float: right;
  width: 30%;
  text-align: center;
  background-color: #F8EEEA;
  border-radius: 15px;
  padding: 2%;
}

.file-upload {
  margin: 0 auto;
  width: auto;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  color: black;
  grid-gap: 5%;
  gap: 5%;
}

.file-upload img {
  width: 50%;
}

.view-img {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.view-img:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.view-img:active {
  box-shadow: none;
  transition: all 0.3s ease-in;
}

.remove-img {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.remove-img:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.remove-img:active {
  box-shadow: none;
  transition: all 0.3s ease-in;
}

.upload-img {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.upload-img:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.upload-img:active {
  box-shadow: none;
  transition: all 0.3s ease-in;
}

.fileupload-holder {
  /* box-shadow: 0px 5px 6px #e1e1e1; */
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  margin-top: 3%;
}

.csoname-holder {
  background-color: #EFF5F6;
  border-radius: 15px;
  padding: 2%;
  margin-bottom: 3%;
  text-transform: capitalize;
}

span.cbfoot-img-holder-submit {
  background-color: #37AB1B;
  display: inline-block;
  width: 25px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

.cdb-confirm-box {
  width: 100%;
  height: auto;
  border-radius: 15px;
  padding: 4%;
  padding: 2%;
}

.cdb-confirm-box img {
  width: 100%;
}

/* CashDepositeBank End */
.log-out {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.log-out:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.log-out:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.log-out img {
  width: 50%;
  height: 50%;
  border-radius: 50px;
  margin: 0;
}

.log-out-deposit {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.log-out-deposit:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.log-out-deposit:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.log-out-deposit img {
  width: 50%;
  height: 50%;
  border-radius: 50px;
  margin: 0;
}

.log-out-history {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.log-out-history:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.log-out-history:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.log-out-history img {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  margin: 0;
}

.log-out-report {
  width: 55px;
  float: right;
  height: 55px;
  border-radius: 50%;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.log-out-report:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.log-out-report:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.log-out-report img {
  width: 50%;
  height: 50%;
  border-radius: 50px;
  margin: 0;
}

/* call list cso starts*/
.call_detail_main,
.call_detail_caller_info {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: auto;
  margin-top: 5%;
  background-color: white;
  border-radius: 13px;
  padding: 5%;
}

.call_detail .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.call_detail .button:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.call_detail .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.call_detail .button img {
  width: 50%;
}

.call_detail .nav_heading {
  font-size: 26px;
  font-weight: 400;
}

.call_detail_nav {
  width: 100%;
  margin: auto;
  margin-top: 5%;
  border-radius: 13px;
  padding: 5%;
  min-height: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 3%;
  gap: 3%;
}

#date_select {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


/* Switch 1 Specific Styles Start */
input[type="checkbox"].switch_1 {
  font-size: 20px;
  -webkit-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all .2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"].switch_1:checked {
  background: #0ebeff;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  -webkit-transform: scale(.7);
  transform: scale(.7);
  left: 0;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

.call_detail .back_button {}

.call_detail .search_button {}

.call_detail .call_button {
  margin-left: auto;
}

.call_detail_caller_info {
  height: auto;
}

.call_detail_main {
  min-height: 402px;
  padding: 5%;
  margin-bottom: 20px;
}

.call_detail_main .heading {
  width: 100%;
  background: #EFF5F6;
  border-radius: 8px;
  padding: 0.5em;
  padding-left: 1em;
  height: auto;
  font-size: 20px;
  margin: 2% auto;
}

.call_detail_main .radio_input {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.call_detail_main .select_input {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call_detail_main .select_input select {
  width: 35%;
  height: 53px;
  border-radius: 11px;
  outline: #1FA1C1;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.call_detail_caller_info .name {
  all: unset;
  font-size: 20;
  font-weight: 400;
  color: #1FA1C1;
}

.call_detail_caller_info .time_at {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 2% auto;
}

.call_detail_caller_info .time_at img {
  width: 30px;
  height: 30px;
}

.call_detail_caller_info .time_at span {
  width: -webkit-max-content;
  width: max-content;
  height: auto;
  border-radius: 0.2em;
  color: white;
  background-color: #1FA1C1;
  padding: 0.2em;
}

/* call list cso ends*/
/* utility classes */
.green_bg {
  background-color: #D2F9D0;
}

.red_bg {
  background-color: #F9531211;
}

.green_text {
  color: #3BC435;
}

.red_text {
  color: #DE8765;
}

.relative {
  position: relative;
}

/* utility class ends */
label.PayLinkLabel {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

p.PayLinkLabel {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

marquee.marquee_data {
  font-size: 24px;
  font-weight: bold;
}

.timer_data {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2%;
}

.export_button button {
  border-radius: 5px;
  font-weight: 600;
}

.report_sumText {
  font-weight: bold;
}

.ant-table-row {
  background-color: #ffffff;
  font-weight: 500;
}

.cheque-upload {
  text-align: center;
}

.cheque-upload img {
  width: 17%;
}

.icon_popup_back {
  width: 5% !important;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  margin-right: 2%;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget span {
  display: none !important;
}

.goog-te-combo {
  color: #000000;
}

div#google_translate_element {
  text-align: -webkit-right;
  margin-top: 2%;
}

.w33 {
  width: 33%;
}

.f16 {
  font-size: 16px;
}

.pl10 {
  padding-left: 10%;
}

.transaction-msg {
  text-align: center;
  padding: 1%;
  color: red;
  font-size: 22px;
  font-weight: 600;
}

/* Responsive */

/* support ticket form */
.support_nav_section {
  background-color: #23A3E1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: auto;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.support_navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.support_navbar span {
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.support_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.support_navbar .button:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.support_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.support_navbar .button img {
  width: 50%;
}

.support_form_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
}

.support_form_body form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.support_form {
  width: 100%;
}

.support_form select {
  width: 90%;
  height: 53px;
  border-radius: 11px;
  background-color: inherit;
  color: rgb(123, 123, 123);
  outline: #1FA1C1;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.support_form input[type=text] {
  width: 90%;
  height: 53px;
  border-radius: 11px;
  outline: #1FA1C1;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 5px;
  background-color: inherit;
}

.support_form textarea {
  width: 90%;
  min-height: 150px;
  border-radius: 11px;
  outline: #1FA1C1;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background-color: inherit;
}

.support_form .support_file_upload {
  width: 90%;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  height: 53px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.support_form .support_file_upload span {
  color: rgb(123, 123, 123);
  margin-left: 4px;
}

.support_form .support_file_upload input {
  display: none;
}

.support_form .st_audio_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  padding: 5px;
}

.support_form .audio-controls {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}

/* support ticket list */
.support_ticket_list {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}

.support_list_navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.support_list_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.support_list_navbar .button:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.support_list_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.support_list_navbar .button img {
  width: 50%;
}

.support_list_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.support_list_filters button {
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0rem 0.6rem;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 11px;
  height: 45px;
  flex-grow: 0;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.support_list_filters button:active {
  background-color: whitesmoke;
}

.support_filter_menu_cnt li:hover {
  background-color: whitesmoke;
}

.support_list_search_box {
  width: 100%;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 19px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}

.support_list_search_box span {
  color: #1FA1C1;
  font-size: 19px;
  font-weight: 500;
  width: 100%;
}

.support_list_search_box input {
  width: 100%;
  border-radius: 50px;
  height: 53px;
  padding-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.support_list_ticket_preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 15px 19px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.support_list_ticket_summary {
  font-size: 15px;
  width: 100%;
  text-align: justify;
  font-weight: 500;
}

.support_list_ticket_created_at {
  color: #A19F9F;
  font-size: 14px;
}

.support_list_ticket_status {
  width: 100px;
  height: 28px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #8F9397;
  border-radius: 5px;
  padding: 0px 5px;
}

.ticket_status_resolved {
  background-color: #63CA3C;
}

.ticket_status_inwork {
  background-color: #FF8B03;
}

.ticket_status_inqueue {
  background-color: #02A6FC;
}

/* support ticket list */

/* support ticket */
.support_ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.support_ticket_details {
  margin-top: 15px;
  background: white;
  border-radius: 13px;
  min-height: 400px;
  width: 100%;
  padding: 18px 25px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.support_ticket_created_at {
  color: #A19F9F;
  font-size: 14px;
}

.support_ticket_status {
  width: 100px;
  height: 28px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;

  background-color: #8F9397;
  padding: 0px 5px;
}

.support_ticket_summary {
  font-size: 15px;
  width: 85%;
  font-weight: 500;
  color: #2CA6C4;
}

.support_ticket_ctpr {
  font-size: 15px;
  padding: 0.5em 1.4em;
  background-color: #EFF5F6;
  border-radius: 8px;
  word-wrap: break-word;
  max-width: 60%;
}

.support_ticket_description {
  width: 85%;
  margin: 0 auto;
  font-size: 16px;
}

.support_ticket_attachment_cnt {
  display: flex;
  width: 85%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.support_ticket_attachment {
  width: 155px;
  background: whitesmoke;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.attachment_image {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

.attachment_image::after {
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, .2);
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
}

.attachment_image:hover::after {
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease-in;
}

.attachment_image img {
  width: 100%;
  margin: auto;
}


.attachment_label {
  padding: 10px;
  background-color: white;
  text-decoration: none;
  font-size: 14px;
  width: 100%;
}

.attachment_label div {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.click_to_download {
  font-size: 14px;
  font-weight: 500;
  color: lightblue;
  cursor: pointer;
  text-decoration: underline;
}

.support_ticket_heading {
  font-weight: 500;
  font-size: medium;
  margin: 10px 0;
}

.support_ticket_comment_heading {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.support_ticket_comment_heading .comment_author {
  font-size: 15px;
  font-weight: 500;
}


.support_ticket_comment_heading .comment_time {
  font-size: 13px;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
}

.support_ticket_comment .comment_body {
  font-size: 16px;
  margin-left: 20px;
}

.support_ticket_comment {
  width: 100%;
  margin-left: 20px;
}

.support_ticket_comment_list {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.support_ticket_add_comment {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support_ticket_add_comment_legend {
  width: 40px;
  height: 40px;
  font-size: small;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 1.5em;
  background-color: #6579fd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_ticket_add_comment_form {
  width: 95%;
  position: relative;
}

.support_ticket_comment_action {
  position: absolute;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  right: 0;
  margin: 2px;
}

/* support ticket */

/* Profile Start */
.profile-image-holder img {
  background-color: #49BB4332;
  padding: 5%;
  border-radius: 10px;
}

span.profile-name {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

span.profile-contact {
  font-size: 15px;
  font-weight: 400;
}

.seclabel {
  font-size: 17px;
  font-weight: 400;
  padding-top: 3%;
}

.profile-notif-holder img {
  background-color: #49BB4332;
  border-radius: 10px;
  padding: 10%;
}

.profile-adrenalin-holder img {
  background-color: #A3C8E1;
  border-radius: 10px;
  padding: 10%;
}

.profile-resetpass-holder img {
  background-color: #1FA1C132;
  border-radius: 10px;
  padding: 10%;
}

.profile-logout-holder img {
  background-color: #D9724A32;
  border-radius: 10px;
  padding: 10%;
}

.move-end {
  float: right;
}

.move-end img {
  width: 35px;
}

span.profile-logout-name {
  font-size: 18px;
  color: #F20F0F;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

.resetpass {
  padding: 5%;
}

img.resetview-pass {
  width: 40px;
  float: right;
  margin-right: 10px;
  margin-top: -50px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Profile End */
/* Notification Start */
.user_icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background-color: #1FA1C1; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_icon img {
  width: 80%;
  height: 80%;
}

/* Dashboard Update End */
/* Dashboard Update */
.user_icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background-color: #1FA1C1; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_icon img {
  width: 80%;
  height: 80%;
}

span.notidesc {
  font-size: 17px;
  font-weight: 400;
}

.notification {
  border-bottom: 1px solid #d9d6d6;
}

/* Dashboard Update End */

.box {
  width: 400px;
  margin-top: 400px;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.137);
  transition: all .4s;
  background: white;
  border-radius: 10px;
  height: 330px;
  overflow-x: hidden;
  z-index: 100000;
  visibility: hidden;
  opacity: 0;
  transition: 0.35s ease-out;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.6em 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.082);
}

.header a {
  text-decoration: none;
}

.content {
  width: 100%;
}

.notification {
  width: 400px;
  padding: 0.6em 1em;
  display: flex;
  align-items: center;
}

.notification img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 0.5em;
}

.notification .text p {
  font-size: 0.8em;
}

.notification .text p span {
  font-weight: 700;
}

.notification .text .time {
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.61);
}

.logo-area .btn11:focus~.box {
  outline: none;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  visibility: visible;
  opacity: 1;
}

.notiuicon {
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-radius: 100px;
  padding: 3%;
  font-weight: 600;
  margin-right: 5%;
}

/* Notification End */
/* NACH Type Start */
.webkitcenter {
  text-align: -webkit-center;
}

.pnachimage-holder {
  text-align: center;
  width: 30%;
  border-radius: 100px;
  background-color: #10928B;
}

.pnachimage-holder img {
  padding: 17%;
  margin-left: 7%;
}

.nbnachimage-holder {
  text-align: center;
  width: 30%;
  border-radius: 100px;
  background-color: #0178B7;
}

.nbnachimage-holder img {
  padding: 17%;
}

.dcnachimage-holder {
  text-align: center;
  width: 30%;
  border-radius: 100px;
  background-color: #AB720F;
}

.dcnachimage-holder img {
  padding: 17%;
}

/* NACH Type End */
/* NACH Register form start */
img.pnachreg-img {
  width: 30px;
}

.nachreg_icon_bg {
  background-color: rgb(31 161 193) !important;
}

/* NACH Register form end */
/* support ticket form ends */
/* NACH Success start */
.nach_suc_title h2 {
  font-size: 18px;
  padding: 3%;
}

.nach_suc_title h2 span {
  color: #1FA1C1;
}

.nach_suc_title h2 img {
  width: 25px;
}

.success-homebtn img {
  width: 25px;
  padding: 2%;
}

.success-homebtn button {
  border-radius: 100px;
  font-size: 20px;
  font-weight: 400;
}

.pop-title {
  font-size: 20px;
  padding: 2%;
  /* text-align: -webkit-center; */
}

.nach-img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  padding: 2%;
  text-align: center;
  justify-content: center;
  margin-right: 5px;
}

.nachreg_icon_bg {
  background-color: #ddd4ca !important;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 4%);
}

.icnsize {
  width: 70%;
}

.stsr {
  float: right;
  color: #fff;
  background-color: red;
  padding: 5px;
  border-radius: 10px;
  font-size: 15px;
}

/* NACH Success End */

/* Reversal */
.reversal_navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.reversal_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.reversal_navbar .button:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.reversal_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.reversal_navbar .button img {
  width: 50%;
}

.reversal_card_holder {
  margin-top: 30px;
}

.reversal_trans_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, .1);
  padding: 12px;
}

.reversal_trans_card_header {
  padding: 10px;
  display: flex;
  align-items: center;
}

.reversal_trans_card_header>div {
  width: 60px;
  height: 60px;
  font-size: 20px;
  display: grid;
  place-items: center;
  font-weight: 800;
  background-color: #39A3C6;
  color: white;
  border-radius: 10px;
  flex-shrink: 0;
}

.reversal_trans_card_header ul {
  margin: 0;
  list-style: none;
  padding-left: 18px;
}

.reversal_trans_card_header ul li:nth-child(n) {
  font-weight: 400;
  font-size: 0.9rem;
  padding-top: 0.3rem;
}

.reversal_trans_card_header ul li:nth-child(1) {
  font-weight: 500;
  font-size: 1rem;
}

.reversal_raised_by {
  margin: 0;
  list-style: none;
  padding-left: 18px;
  font-weight: 400;
  font-size: 0.9rem;
  padding-top: 0.3rem;
}

.reversal_trans_card_amount {
  display: flex;
  justify-content: space-between;
  padding: 1.25em 1.6em;
  color: #20A1C1;
  background-color: #EFF5F6;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
}

.reversal_trans_card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 0.9rem;
  color: #A7A6A6;
}

.reversal_trans_card_footer ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

/* .reversal_dashboard_search {
  width: 100%;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 19px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
} */
.reversal_dashboard_search {
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border: none;
}

/* .reversal_dashboard_search span {
  color: #1FA1C1;
  font-size: 19px;
  font-weight: 500;
  width: 100%;
} */

/* .reversal_dashboard_search input {
  width: 100%;
  border-radius: 50px;
  height: 45px;
  padding-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
} */

.reversal_dashboard_filter .dropdown button {
  border: none;
  cursor: pointer;
  outline: 1px solid grey;
  font-size: 1rem;
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 11px;
  height: 45px;
  flex-grow: 0;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.reversal_dashboard_filter button:active {
  background-color: whitesmoke;
}

.reversal_dashboard_filter .dropdown button img {
  width: 20px;
  height: 20px;
}


/* Reversal End */

/* submit reversal */
.reversal_details_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, .1);
  padding: 12px;
  margin-top: 20px;
}

.reversal_details_card>* {
  width: 100%;
}

.reversal_details_card_header {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #EFF5F6;
  border-radius: 10px;
}

.reversal_details_card_header>div {
  padding: 20px;
  font-size: 20px;
  font-weight: 800;
  background-color: #0178B7;
  color: white;
  border-radius: 10px;
}

.reversal_details_card_header ul {
  margin: 0;
  list-style: none;
  padding-left: 18px;
}

.reversal_details_card_header ul li:nth-child(1) {
  font-weight: 500;
  font-size: 1rem;
}

.reversal_details_card_header ul li:nth-child(2) {
  font-weight: 400;
  font-size: 0.9rem;
}

.reversal_details_card_header ul li:nth-child(3) {
  font-weight: 400;
  font-size: 0.9rem;
  padding-top: 0.3rem;
}

.reversal_details_bal_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .15);
  padding: 14px 10px;
}

.reversal_details_bal_block h4 {
  font-size: 1.1rem;
  font-weight: 500;
}

.reversal_bal_block_main {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: space-between;
}

.reversal_bal_block_main>div {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 1rem;
  font-weight: 450;
}

.reversal_bal_block_main>div>span:nth-child(2) {
  padding: 0.81em 1.1em;
  border-radius: 8px;
  text-align: center;
}

.reversal_bal_overdue {
  color: #D57551;
}

.reversal_bal_overdue>span:nth-child(2) {
  background-color: rgba(219, 45, 100, 0.15);
}

.reversal_bal_charges {
  color: #43A7C8;
}

.reversal_bal_charges>span:nth-child(2) {
  background-color: #EFF5F6;
}

.reversal_bal_emi {
  color: #AE9410;
}

.reversal_bal_emi>span:nth-child(2) {
  background-color: rgba(248, 235, 173, 0.97)
}

.reversal_details_card textarea {
  min-height: 115px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .15);
  resize: none;
  padding: 1rem;
}

.reversal_button {
  border-radius: 5px;
  color: white;
  background-color: #1FA1C2;
  font-size: 20px;
  padding: 0.8em 1em;
  cursor: pointer;
  border: none;
  outline: none;
  max-width: 300px;
  height: 58px;
}

.reversal_button:hover {
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
}

.reversal_button:focus {
  border: none;
  outline: 4px solid rgba(76, 167, 183, 0.3);
}

.reversal_act_rjt {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.reversal_act_rjt>button:nth-child(1) {
  width: 40%;
}

.reversal_act_rjt>button:nth-child(2) {
  width: 40%;
}

/* submit reversal */

/* update Call Detail Start */
.load-dataholder {
  margin-top: 5%;
  width: 100%;
}

span.loan-title {
  padding-left: 1%;
}

.loan-nach-img {
  box-shadow: 0px 5px 6px #e1e1e1;
  float: right;
  padding: 1%;
  border-radius: 50px;
}

.loan-nach-img img {
  width: 30px;
  height: 30px;
}

.last-pull {
  background-color: #FFF7F2;
  margin-top: 4%;
  border-radius: 10px;
  padding: 2%;
}

.pull-label {
  font-size: 17px;
}

.lastpull-data {
  display: flex;
  text-align: center;
}

.each-monthdata {
  padding: 1%;
  width: 17%;
  text-align: -webkit-center;
}

.each-monthdata span {
  font-size: 15px;
  font-weight: normal;
}

.psts {
  text-align: center;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  font-weight: 500;
  color: #fff;
  padding: 7%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.sucs {
  background-color: #1BC992;
  border: 1px solid #33AF87;
}

.fail {
  background-color: #F79B57;
  border: 1px solid #DB6A16;
}

.loanradio_input {
  float: right;
  display: flex;
}

.loanpaymode {
  float: right;
}

.balance {
  background-color: #EFF5F6;
  padding: 2%;
  border-radius: 10px;
  min-height: 50px;
}

.balance-premode {
  background-color: #EFF5F6;
  padding: 2%;
  border-radius: 10px;
  min-height: 60px;
}

input[type="checkbox"].loanswitch_1 {
  font-size: 20px;
  -webkit-appearance: none;
          appearance: none;
  width: 3em;
  height: 1.2em;
  background: #fff;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all .2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"].loanswitch_1:after {
  position: absolute;
  content: "";
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: #1FA1C1;
  box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
  -webkit-transform: scale(.7);
          transform: scale(.7);
  left: 0;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].loanswitch_1:checked {
  background: #fff;
}

.loanradio_input span {
  padding: 0 3%;
  width: 50px;
}

.pref-date {
  padding: 2%;
  background-color: #ECFEE0;
  border-radius: 10px;
  min-height: 60px;
}

.perfcollect {
  float: right;
}

.loan-prefdate {
  border: 1px solid #5AB91A66;
  border-radius: 10px;
  padding: 1%;
  text-align: center;
}

.update-btn {
  text-align: center;
  padding: 2%;
}

input.updbtn {
  border-radius: 10px;
  width: 30%;
  padding: 1%;
  font-weight: 500;
  font-size: 16px;
}

.loan-headtitle {
  width: 100%;
  padding: 2%;
  background-color: #EFF5F6;
  font-weight: 500;
  font-size: 19px;
}

.name span {
  margin-right: 1%;
  background-color: #39A3C6;
  padding: 2%;
  border-radius: 10px;
  text-transform: uppercase;
}

span.m1 {
  position: absolute;
  top: 38%;
  left: 12%;
}

span.m2 {
  position: absolute;
  top: 38%;
  left: 28%;
}

span.m3 {
  position: absolute;
  top: 38%;
  left: 43%;
}

span.m4 {
  position: absolute;
  top: 38%;
  left: 58%;
}

span.m5 {
  position: absolute;
  top: 38%;
  left: 74%;
}

span.m6 {
  position: absolute;
  top: 38%;
  left: 89%;
}

span.icon_pull img {
  width: 25px;
  height: 25px;
}

span.pullamt {
  background-color: #BBEAE3;
  padding: 5%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
}

img.loan-inrsym {
  width: 15%;
  height: 16%;
  padding: 1%;
  margin: 1%;
}

img.smsmobedt {
  /* background-color: #D27D5C;
  width: 60%;
  padding: 10%;
  border-radius: 50%; */
  background-color: #D27D5C;
  width: 10%;
  padding: 2%;
  border-radius: 50%;
  position: absolute;
  top: 35px;
  right: 19px;
}

/* update Call Detail End */

/* payload_view css */
.payload_tiles {
  margin-top: 28px;
}

.payload_view_navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.payload_view_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.payload_view_navbar .button:hover {
  background-color: #DDEBEE;
  transition: all 0.3s ease-out;
}

.payload_view_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.payload_view_navbar .button img {
  width: 50%;
}

.payload_view_main_block {
  margin-top: 1rem;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  padding-top: 10px;
  /* padding-top: 25px; */
  padding-bottom: 5px;
  padding-inline: 10px;
}

.payload_view_table_block {
  width: 100%;
  overflow-x: auto;
  /* margin-top: 20px; */
}

.payload_view_rows_count {
  font-weight: bold;
  display: flex;
  flex-direction: row-reverse;
}

.payload_view_table {
  width: 100%;
}

.payload_view_table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.payload_view_table th {
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #29545F;
  font-size: small;
}

.payload_view_table th,
.payload_view_table td {
  padding: 10px 7px;
  width: 180px;
  text-align: -webkit-center;
}

.payload_view_table th input[type='text'] {
  max-width: 150px;
}

.payload_view_table tr {
  background-color: #FCFCFC;
}

.payload_view_table tr td:last-child {
  width: 260px;
}

.payload_view_table .payload_view_table_actions {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
}

.payload_view_table tr:nth-child(2n-1) {
  background-color: #ECEDED;
}

/* payload_view css end*/

@media (max-width: 390px) {
  /* .col_block {
    width: 140px;
  } */

  /* .bal_block {
    width: 140px;
  } */

  .card-header-container .date-time span {
    color: #818485;
    font-size: 14px;
  }

  .amt-container .bal-amount {
    width: 130px;
  }

  .amt-container .hnd-amount {
    width: 130px;
  }

  .dn-red-amt {
    width: 60px;
  }

  .dis_block {
    width: 130px;
  }

  .dn-header-text {
    font-size: 14px;
  }

  img.smsmobedt {
    background-color: #D27D5C;
    width: 15%;
    padding: 3%;
    border-radius: 50%;
  }
}

@media(max-width:407px) {
  .cash-id {
    font-size: 16px;
  }

  .csoname-holder {
    font-size: 16px;
  }

  img.smsmobedt {
    background-color: #D27D5C;
    width: 15%;
    padding: 3%;
    border-radius: 50%;
  }

  .image-holder-2 {
    text-align: center;
    width: 70px;
  }

  .image-holder-2 img {
    /* background-color: #0178B7; */
    border-radius: 100px;
    /* padding: 10px; */
    width: 70px;
  }

  .image-holder-2 h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
  }
  
  .image-report-2 {
    text-align: center;
    width: 120px;
  }

  .image-report-2 img {
    /* background-color: #0178B7; */
    border-radius: 100px;
    /* padding: 10px; */
    width: 55px;
  }

  .image-report-2 h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width : 500px) {
  .call_detail .button {
    width: 40px;
    height: 40px;
  }

  .support_navbar .button {
    width: 40px;
    height: 40px;
  }

  .support_list_navbar .button {
    width: 40px;
    height: 40px;
  }

  .reversal_navbar .button {
    width: 40px;
    height: 40px;
  }

  .call_detail .nav_heading {
    font-size: 20px;
  }

  .call_detail_main .heading {
    font-size: 18px;
  }

  .cr_msg_block {
    margin-top: 70%;
  }

  .dn-header-text {
    font-size: 15px;
  }

  a>.icon_bg {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
  }

  .view-img {
    width: 50px;
    height: 50px;
  }

  .remove-img {
    width: 50px;
    height: 50px;
  }

  .upload-img {
    width: 50px;
    height: 50px;
  }

  .msg_icon_bg {
    width: 40px;
    height: 40px;
  }

  .upload_icon_bg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .call_icon_bg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .log-out-deposit {
    width: 40px;
    height: 40px;
    flex-shrink: 0
  }

  .icon_bg {
    height: 40px;
    width: 40px;
    flex-shrink: 0
  }

  .back_icon_bg {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
  }

  .log-out-report {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .trans_back_icon_bg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    flex-shrink: 0;
  }

  .log-out-history {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .log-out {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  img.smsmobedt {
    background-color: #D27D5C;
    width: 15%;
    padding: 3%;
    border-radius: 50%;
  }
}

@media only screen and (min-width : 1200px) {
  img.smsmobedt {
    /* background-color: #D27D5C;
    width: 42%;
    padding: 10%;
    border-radius: 50%; */
    background-color: #D27D5C;
    width: 7%;
    padding: 1%;
    border-radius: 50%;
  }

  .collected-holder {
    width: 30%;
    position: relative;
    display: inline-block;
    margin-left: -1%;
  }

  .balance-holder {
    display: inline-block;
    position: relative;
    padding-left: 0%;
    width: 30%;
  }

  .name-holder h2 {
    width: 350px;
  }

  /* .card-border-small {
    height: 170px;
  } */

}

/* Responsive End */
.ring.animated,
.ring.animated-hover:hover,
.ring-parent.animated-hover:hover>.ring {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg)
  }

  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }

  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg)
  }

  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg)
  }

  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg)
  }

  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg)
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg)
  }

  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }

  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg)
  }

  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg)
  }

  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg)
  }

  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg)
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

/* Dashboard Update */
.user_icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background-color: #1FA1C1; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_icon img {
  width: 80%;
  height: 80%;
}

/* Dashboard Update End */


/* Vaibhav Update 19/10/2022 */
.approver_block {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 20px;
}

.approver_block .head-title {
  font-weight: bold;
  font-size: 16px;
}

/*          END              */

/* spin start */
.loader {
  /* Default loader start to hide default comment below code */
  border: 16px solid #D4D4D4;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  /* Default loader end */
  background-color: transparent;
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999999;
  /* show image in loader start */
  /* background-image: url('../images/acen_Logo.png');
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%; */
  /* show image in loader end */
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: rgb(23 22 22 / 39%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s linear;
}

.preloader img {
  height: 200px;
  width: 200px;
}

.page-content {
  max-width: 768px;
  margin: 0 auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* spin end */
/* CSO Name internet speed and TAT Start */
.support_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  z-index: 9999;
  /* right: 10px; Float Chat Button to left*/
}

.support_drag_handle {
  width: 25px;
  height: 25px;
  position: relative;
  right: -40px;
  top: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.support_drag_handle_hovered {
  background-color: #a18ced88;
}

.support_drag_handle img {
  width: 110%;
  height: 110%;
}


/*chat Btn*/
.chatSupportBtn {
  all: unset;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #1FA1C1;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatSupportBtn:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.chatSupportBtn:focus {
  outline: none;
}

.chatSupportBtn img {
  width: 80%;
  height: 80%;
}

.loguname {
  all: unset;
  height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  font-weight: 500;
  width: 100px;
  -webkit-transform: translateY(-10px) translateX(4px);
          transform: translateY(-10px) translateX(4px);
  position: relative;
  z-index: -1;
}

text {
  font-size: 15px;
  border: none;
  font-weight: 500;
}

.wifi-data {
  height: auto;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 500;
  width: 100px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navtime {
  height: auto;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 500;
  width: 100px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input.form-control::-webkit-input-placeholder {
  padding-left: 2%;
}

input.form-control::placeholder {
  padding-left: 2%;
}

img.view-pass-change {
  float: right;
  margin-right: 10px;
  margin-top: -40px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* CSO Name internet speed and TAT End */
/* img.smsmobedt {
  background-color: #D27D5C;
  width: 42%;
  padding: 10%;
  border-radius: 50%;
} */
/* maintaenance Start */
.maintaenance-text {
  color: red;
}

.maintaenance-container {
  text-align: center;
  background-color: #fff;
  width: 300px;
  margin: 0 auto;
  padding: 3em 1em 1em;
  position: relative;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.maintaenance-image {
  width: 180px;
  margin: 0 auto;
}

span.maintaenance-text p {
  font-size: 16px;
  margin: 10% 0;
}

/* maintaenance End */
/* foreclose Start */
.foreclose {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  background: #e9e9e9;
  border-radius: 50px;
  padding: 3%;
  margin: 0;
}

/* foreclose End */
iframe.pdfviewfile {
  height: 500px;
}

.previous_transaction_img {
  width: 35px;
  color: gray;
  font-weight: bold;
}

.blue_block_sh {
  background-color: rgba(31, 161, 193, 0.16);
  height: 93px;
  width: 100%;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 10%;
  padding-bottom: 50%;
  margin-bottom: 5%;

  color: #1FA1C1;
}

.green_block_sh {
  background-color: #9BFF95;
  height: 93px;
  width: 100%;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 12%;
  margin-bottom: 5%;
  color: #2F5D2C;
}


.red_block_sh {
  background-color: #FFBEA5;
  height: 93px;
  width: 100%;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 12%;
  margin-bottom: 5%;
  color: #8A472D;
}


.filter_button_container_sh {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  padding: 10px;
  border-radius: 8px;
  /* color: white; */
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}


.countLabel {
  font-size: 20px;
}

hr {
  margin-top: 1%;

}

.redtext_sh {
  color: #8A472D;
  font-size: 18px;
}

.greentext_sh {
  color: #2F5D2C;
  font-size: 18px;
}


.greentext {
  color: #0AAF0F;
  font-size: 15px;
  text-align: center;
}

.redtext {
  color: #C52B13;
  font-size: 15px;
  text-align: center;
}

.emi_block {
  background-color: #eeca7c;
  width: 155px;
  height: 53px;
  margin-top: 5%;
  padding-top: 8%;
  color: black;
  border-radius: 15px;
}

span.subheadfour {
  color: #1a11bd;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
}

span.subheadfive {
  color: #d20b7f;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
}

.d_block_amt {
  margin-top: 2%;
}

.utrTable>tbody>tr:nth-child(odd)>td,
.utrTable>tbody>tr:nth-child(odd)>th {
  background-color: #ECEDED;
}



.utrTable>thead>tr {
  background-color: #29545F;
  color: white;
}

.logo-box img {
  width: 55px;
  height: 55px;
}

.payload-openmodal {
  cursor: pointer;
  color: #02A6FC;
}

/* .tableFixHead {
  height: 500px;
	overflow-y: auto;
} */
.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 800px;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header thead {
  background: black;
  color: #fff;
}

.up-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 7px white;
  border-top-width: 0;
  cursor: pointer;
  position: absolute;
  margin-top: 7px;
  margin-left: 4px;

}

.down-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 7px white;
  border-bottom-width: 0;
  margin-top: 1px;
  cursor: pointer;
  position: absolute;
  margin-top: 7px;
  margin-left: 4px;
}

/* Esign Start */
.card-holdermain {
  margin-top: 3%;
  background: #fff;
  border-radius: 10px;
  padding: 2%;
}

label.search-label {
  font-size: 18px;
  font-weight: 600;
}

.search-input {
  background: #F9F9F9;
  border: 1px solid rgba(11, 115, 140, 0.66);
  border-radius: 7px;
  line-height: 2rem;
}

button.search-btn {
  background: #13697E;
  border-radius: 7px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  padding: 2% 20%;
  line-height: 2rem;
  width: 100%;
  height: 100%;
}

.captured-img {
  width: 150px;
  height: 150px;
  margin-left: 2%;
}

.webcam video {
  width: 60%;
  height: 80%;
  float: left;
}

/* .hhdatacard {
  background: rgba(31, 161, 193, 0.06);
  border: 1px solid #1FA1C1;
  border-radius: 7px;
  padding: 1%;
  display: flex;
  flex-direction: row;
}
.hhimage-holder{
  padding: 1%;
}
img.hhimage {
  height: 100px;
  width: 100px;
}
.hhcamera {
  height: 35px;
  width: 35px;
  position: relative;
  top: 40%;
  right: 13%;
  background: #fff;  
  border-radius: 50px;
}
.hhdetail {
  padding: 1%;
}
img.hhnumbercamera {
  height: 25px;
  width: 25px;
  margin-left: 3%;
} */
.hhnamelabel {
  color: #13697E;
  font-size: 18px;
  font-weight: 700;
}

.hhname {
  font-size: 18px;
  text-transform: uppercase;
}

.hhnumber {
  color: #8B8888;
  font-size: 17px;
}

.generate-btn {
  background: #13697E;
  border-radius: 7px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  padding: 1% 4%;
  line-height: 2rem;
  height: 100%;
  margin: 2%;
}

.generategrey-btn {
  background: grey;
  border-radius: 7px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  padding: 1% 4%;
  line-height: 2rem;
  height: 100%;
  cursor: not-allowed;
  margin: 2%;
}

@media (max-width: 1200px) {
  .main-container {
    margin: 0 auto;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
  }

  .main-container .card-container {
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    padding: 10px;
    flex-wrap: wrap;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .main-container .card-container .input {
    /* border: 1px solid; */
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .main-container .card-container .input .intp input {
    width: 464px;
    height: 41px;
    border-radius: 7px;
  }

  .main-container .card-container .input .intp button {
    width: 156px;
    height: 47px;
    border-radius: 7px;
    font-size: larger;
    font-weight: 700;
  }

  .main-container .card-container .card-holder {
    border: 1px solid #1fa1c1;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: rgba(31, 161, 193, 0.06);
    flex-wrap: wrap;
    padding: 2%;
    justify-content: space-evenly;
  }

  .main-container .card-container .card-holder figure {
    width: 80px;
    height: 90px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 4rem;
  }

  .main-container .card-container .card-holder span img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder figure img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .applicant-info {
    position: relative;
    width: 22%;
  }

  .main-container .card-container .card-holder .iconed {
    width: 25px;
    height: 25px;
    margin-left: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs {
    /* border: 1px solid; */
    /* width: 14rem;
    height: 6rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-container .card-container .card-holder .imgs .circle {
    /* border: 1px solid; */
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    margin: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs .img1 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .img1 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .sideimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    left: 17px;
    bottom: 30px;
  }

  .main-container .card-container .card-holder .imgs .sideimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 37px;
    right: 21px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg2 {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 42px;
    right: 30px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg2 img {
    width: 15px;
    height: 15px;
  }

  button {
    background: #13697e;
    border: none;
    color: white;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .main-container {
    margin: 0 auto;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
  }

  .main-container .card-container {
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    /* justify-content: space-evenly; */
    padding: 10px;
    flex-wrap: wrap;
  }

  .main-container .card-container .input {
    /* border: 1px solid; */
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .main-container .card-container .input .intp input {
    width: 464px;
    height: 41px;
    border-radius: 7px;
  }

  .main-container .card-container .input .intp button {
    width: 156px;
    height: 47px;
    border-radius: 7px;
    font-size: larger;
    font-weight: 700;
  }

  .main-container .card-container .card-holder {
    border: 1px solid #1fa1c1;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: rgba(31, 161, 193, 0.06);
    flex-wrap: wrap;
    padding: 2%;
    justify-content: space-evenly;
  }

  .main-container .card-container .card-holder figure {
    width: 80px;
    height: 90px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 1.5rem;
  }

  .main-container .card-container .card-holder span img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder figure img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .applicant-info {
    position: relative;
    width: 22%;
  }

  .main-container .card-container .card-holder .iconed {
    width: 25px;
    height: 25px;
    margin-left: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs {
    /* border: 1px solid; */
    /* width: 14rem;
    height: 6rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-container .card-container .card-holder .imgs .circle {
    /* border: 1px solid; */
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    margin: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs .img1 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .img1 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .sideimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    left: 17px;
    bottom: 30px;
  }

  .main-container .card-container .card-holder .imgs .sideimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 37px;
    right: 21px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg2 {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 42px;
    right: 30px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg2 img {
    width: 15px;
    height: 15px;
  }

  button {
    background: #13697e;
    border: none;
    color: white;
    cursor: pointer;
  }
}

@media screen and (max-width: 480px) {
  .main-container {
    margin: 0 auto;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
  }

  .main-container .card-container {
    background-color: white;
    border-radius: 20px;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

  .main-container .card-container .input {
    /* border: 1px solid; */
    height: 11%;
    width: 68%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .intp {
    /* border: 1px solid; */
    display: flex;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }

  .main-container .card-container .input .intp input {
    width: 464px;
    height: 41px;
    border-radius: 7px;
  }

  .main-container .card-container .input .intp button {
    width: 156px;
    height: 47px;
    border-radius: 7px;
    font-size: larger;
    font-weight: 700;
  }

  .main-container .card-container .card-holder {
    border: 1px solid #1fa1c1;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: rgba(31, 161, 193, 0.06);
    flex-wrap: wrap;
    padding: 2%;
    justify-content: space-evenly;
  }

  .main-container .card-container .card-holder figure {
    width: 80px;
    height: 90px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 4rem;
  }

  .main-container .card-container .card-holder span img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder figure img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .applicant-info {
    position: relative;
    width: 22%;
  }

  .main-container .card-container .card-holder .iconed {
    width: 25px;
    height: 25px;
    margin-left: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs {
    /* border: 1px solid; */
    /* width: 14rem;
    height: 6rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-container .card-container .card-holder .imgs .circle {
    /* border: 1px solid; */
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    margin: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs .img1 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .img1 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .sideimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    left: 17px;
    bottom: 30px;
  }

  .main-container .card-container .card-holder .imgs .sideimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 37px;
    right: 21px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg2 {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 42px;
    right: 30px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg2 img {
    width: 15px;
    height: 15px;
  }

  button {
    background: #13697e;
    border: none;
    color: white;
    cursor: pointer;
  }
}

@media screen and (max-width: 360px) {
  .main-container {
    margin: 0 auto;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
  }

  .main-container .card-container {
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    grid-row-gap: 22px;
    row-gap: 22px;
    /* align-content: stretch; */
  }

  .main-container .card-container .input {
    /* border: 1px solid; */
    height: 11%;
    width: 99%;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
  }

  .intp {
    /* padding: 7px 6px 0px 9px; */
    /* border: 1px solid; */
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .main-container .card-container .input .intp input {
    width: 255px;
    height: 41px;
    border-radius: 7px;
    padding: 0px;
  }

  .main-container .card-container .input .intp button {
    width: 258px;
    height: 47px;
    border-radius: 7px;
    font-size: larger;
    font-weight: 700;
  }

  .main-container .card-container .card-holder {
    border: 1px solid #1fa1c1;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: rgba(31, 161, 193, 0.06);
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px 4px 11px;
    justify-content: space-evenly;
  }

  .main-container .card-container .card-holder figure {
    width: 154px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: 1rem;
  }

  h1 {
    margin: 0;
  }

  .main-container .card-container .card-holder span {
    width: 3rem;
    /* height: 2rem; */
    position: relative;
    top: 83px;
    right: 52px;
  }

  .main-container .card-container .card-holder span img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder figure img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .applicant-info {
    position: relative;
    width: 22%;
  }

  .main-container .card-container .card-holder .iconed {
    width: 25px;
    height: 25px;
    margin-left: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs {
    /* border: 1px solid; */
    /* width: 19rem;
    height: 7rem; */
    display: flex;
    align-items: center;
    /* padding: 0px; */
  }

  .main-container .card-container .card-holder .imgs .circle {
    /* border: 1px solid; */
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    margin: 1%;
    cursor: pointer;
  }

  .main-container .card-container .card-holder .imgs .img1 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .img1 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 img {
    width: 100%;
    height: 100%;
  }

  .main-container .card-container .card-holder .imgs .img2 {
    width: 4rem;
    height: 4rem;
  }

  .main-container .card-container .card-holder .imgs .sideimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    left: 17px;
    bottom: 30px;
  }

  .main-container .card-container .card-holder .imgs .sideimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 37px;
    right: 21px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg img {
    width: 20px;
    height: 20px;
  }

  .main-container .card-container .card-holder .imgs .botimg2 {
    width: 2rem;
    height: 1rem;
    position: relative;
    top: 42px;
    right: 30px;
    /* bottom: 0rem; */
  }

  .main-container .card-container .card-holder .imgs .botimg2 img {
    width: 15px;
    height: 15px;
  }

  button {
    background: #13697e;
    border: none;
    color: white;
    cursor: pointer;
  }
}

@media (min-width: 856px) {
  .main-container .card-container {
    background-color: white;
    border-radius: 20px;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

  .main-container .card-container .input .intp input {
    width: 312px;
    height: 41px;
    border-radius: 7px;
  }
}

@media (min-width: 921px) {
  .main-container .card-container {
    background-color: white;
    border-radius: 20px;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

  .main-container .card-container .input {
    /* border: 1px solid; */
    height: 11%;
    width: 66%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1115px) {
  .main-container .card-container .input {
    width: 88%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 4.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 4rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 1.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 4rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .main-container .card-container .card-holder span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 40px;
    right: 4rem;
  }
}

.file-holder {
  border-radius: 10px;
  border: 1px solid;
  padding: 2%;
}

.file-holder label {
  font-size: 16px;
  color: #13697E;
  font-weight: 700;
}

.file-holder button {
  float: right;
  padding: 1% 2% 1% 2%;
  font-size: 16px;
  background: #F2F9FB;
  border-radius: 6px 0px 0px 6px;
  color: #13697E;
  font-weight: 600;
}

.download_btn button {
  background: #13697E;
  box-shadow: 0px 0px 6px #EEEBEB;
  border-radius: 7px;
  padding: 1% 2% 1% 2%;
}

.download_btn button {
  background: #13697E;
  box-shadow: 0px 0px 6px #EEEBEB;
  border-radius: 7px;
  padding: 1% 3% 1% 3%;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}

.generategrey-btn {
  background: grey;
  border-radius: 7px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  padding: 1% 4%;
  line-height: 2rem;
  height: 100%;
  margin: 2%;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.fsize {
  display: flex;
  font-size: large;
  /* display: contents; */
  justify-content: center;
  align-items: baseline;
  grid-gap: 100px;
  gap: 100px;
  flex-wrap: wrap;

}

.report-centre {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.jt {
  padding-right: 18px;
  padding-left: 18px;
  border-radius: 6px;
}

.ht {
  height: 3rem;
  width: 10rem;
}

.ht .pd {
  font-weight: bold;
}

/* Esign End */

/* pentahon-report-popup ----start*/

/* pentahon-report-popup ----end*/
.not-allowed {
  cursor: not-allowed;
  background-color: #dbdada;
  pointer-events: none;
  opacity: 0.7;
  border-radius: 10px;
}

/* AT-1739 | AT-2199 | Ankur Makavana | 19-07-2023 | Enable back camera selection */
.switch-camera {
  width: 30px;
}

.change_cam {
  position: absolute;
  top: 4%;
  left: 3%;
}

.download-link {
  color: #0178B7;
  cursor: pointer;
}

.f18 {
  font-size: 18 px;
}

.btn-sendreq {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.disablesendreqbtn {
  pointer-events: none;
  opacity: 0.7 !important;
  background-color: #60a5ef !important;
}

.inf_loader {
  width: 60%;
}

/* .queryBuilder {
  float: left;
} */
.ruleGroup-addRule,
.ruleGroup-addGroup {
  background: #007BFF;
  padding: 6px 12px;
  border-radius: 5px;
  color: #ffffff;
  margin: 1%;
}
.ruleGroup-combinators {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.rule-fields {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-right: 2px;
}
.rule-operators {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-right: 2px;
}
.rule-value {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-right: 3px;
}
.rule-remove {
  background: #DC3545;
  padding: 6px 12px;
  border-radius: 5px;
  color: #ffffff;
}
.ruleGroup-remove {
  background: #DC3545;
  padding: 6px 12px;
  border-radius: 5px;
  color: #ffffff;
}
.w80fl {
  width: 80%;
  float: left;
}
.w20fl {
  width: 20%;
  float: left;
  flex-basis: auto;
}
.w60p {
  width: 60%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
}
.w20p {
  width: 20%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
}
.hhnumber-holder {
  margin: 10px;
}

iframe {
  border: none;
}

.up-downarrow span {
  font-size: 30px;
  font-weight: 900;
  position: absolute;
}

.report_inputs {
  width: 50%;
  float: left;
  padding-right: 1%;
}

.report_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
}

.report_head span {
  display: flex;
  position: inherit;
}

.show_title h5 {
  color: #8F8E8E;
  font-size: 19px;
}

.show_title {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 3px 10px #DED9D9;
}

.down-arrow-report {
  color: #8F8E8E;

}

.icon_bg-report {
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon_bg-report .icon_img {
  height: 50%;
  width: 50%;
}

.log-out-reporthead {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.log-out-reporthead img {
  width: 50%;
  height: 50%;
  border-radius: 50px;
  margin: 0;
}

.load-btn:active {
  background-color: #12B4D1;
  color: #ffffff;
  font-weight: 700;
}

.last-transaction {
  padding: 1%;
  background: #EFF5F6;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1%;
}

h5#colapse {
  font-size: 15px;
  font-weight: 600;
  color: #8F8E8E;
}

.queryBuilder {
  text-align: left;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
  background: #007BFF;
  padding: 6px 12px;
  border-radius: 5px;
  color: #ffffff;
  margin: 1%;
}

.ruleGroup-combinators {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rule-fields {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-right: 2px;
}

.rule-operators {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-right: 2px;
}

.rule-value {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-right: 3px;
}

.rule-remove {
  background: #DC3545;
  padding: 6px 12px;
  border-radius: 5px;
  color: #ffffff;
}

.ruleGroup-remove {
  background: #DC3545;
  padding: 6px 12px;
  border-radius: 5px;
  color: #ffffff;
}

.showjson {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
  white-space: pre-wrap;
}

.clrgreen {
  color: green;
}

.clrred {
  color: red;
}

.upload-popup {
  position: fixed;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  /* Background color for the popup */
  padding: 80px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;


}

.close-btn {
  position: absolute;
  top: 1px;
  right: 2px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: black !important;
}

.upload-content {
  width: 100%;
}

.upload-popup button {
  margin-top: 25px;
  font-size: 16px;
  padding: 16px 28px;
  color: white;
  font-weight: bold;

}






.exprowbtn {
  margin-right: 5px;
  cursor: pointer;
}

.payload_view_sub_block {
  border-radius: 10px;
  background-color: white;
}

.payload_sub_view_table_block {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.ant-picker {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: calc(2.25rem + 2px);
  text-align: center;
}

.ant-select,
.ant-select-single,
.ant-select-selector {
  width: 100%;
  padding: 0 !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: calc(2.25rem + 2px);
  text-align: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 100% !important;
  padding: 0 !important;
}

.hidden {
  visibility: hidden;
}

.btn-height {
  line-height: 1.2 !important;
}

.position-static {
  display: none;
}

/* For small devices (phones, etc.) */
@media (max-width: 575.98px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 13px;
  }

  h6 {
    font-size: 11px;
  }

  .pv-block-body {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    margin-top: 0%;
    border-radius: 0 0 20px 20px;
    margin: auto;
    padding: 6%;
  }
}

/* For medium devices (tablets, etc.) */
@media (min-width: 576px) and (max-width: 991.98px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  /* You can adjust font sizes for h6 as needed */
}

.table-head {
  margin: 0 0 0 0;
  color: #fff;
  justify-content: space-between;
  background: linear-gradient(45deg, #41D8D1, #01058B);
}

.position-static {
  display: none;
}

/* For small devices (phones, etc.) */
@media (max-width: 575.98px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 13px;
  }

  h6 {
    font-size: 11px;
  }

  .pv-block-body {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    margin-top: 0%;
    border-radius: 0 0 20px 20px;
    margin: auto;
    padding: 6%;
  }
}

/* For medium devices (tablets, etc.) */
@media (min-width: 576px) and (max-width: 991.98px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  /* You can adjust font sizes for h6 as needed */
}

.table-head {
  margin: 0 0 0 0;
  color: #fff;
  justify-content: space-between;
  background: linear-gradient(45deg, #41D8D1, #01058B);
}

.ant-list-bordered{
  border: none !important;
}
.hh_white_block_nach {
    background-color: white;
    width: 100%;
    /* height: 310px; */
    margin: auto;
    margin-top: 5%;
    border-radius: 20px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 2%;
    position: relative;
  }

.name_hh_block_nach {
    /* background-color: red; */
    padding: 5% 5% 5% 5%;
    /* margin: 10px; */
    color: white;
    margin-bottom: 5px;
    display: flex;
    border-radius: 10px 10px 0px 0px;
    /* border-radius: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    justify-content: space-between;
    background-color: #1FA1C1;
}

.upload_icon_bg_nach {
    all: unset;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #13697E;
    cursor: pointer;
    position: absolute;
    right: 5%;
    flex-shrink: 0;
}

.upload_icon_bg_upi {
    all: unset;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    cursor: pointer;
    position: absolute;
    right: 11%;
    flex-shrink: 0;
}

.nach_icon_img {
    width: 60%;
}

.bank_details_nach {
    border: 1px solid black;
    border-radius: 10px;
    margin: 3% 5% 2% 5%;
    /* padding: 2%; */
}

.bank_detail_nach {
    display: flex;
    margin: 1%;
}

.bank_data {
    flex: 0 0 50%;
    padding: 10px;
}

.dotted_line_nach{
    border: 1px dashed black;
    width: 90%;
}

.bank_detail_bottom_block {
    /* background-color: red; */
    padding: 5% 5% 5% 5%;
    /* margin: 10px; */
    width: 100%;
    /* margin-bottom: 5px; */
    display: flex;
    border-radius: 0px 0px 10px 10px;
    /* border-radius: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    justify-content: center;
    background-color: #13697E;
    color: white;
}

.nach_status_dt{
    color: white;
    text-align: center;
}
.text-right{
    margin-right: 5%;
}


.cq-blue-box {
    background-color: #EFF5F6;
    width: 100%;
    height: auto;
    padding: 3%;
    text-align: center;
    border-radius: 20px;
}

.cq-profile-pic {
    border-radius: 50%;
    background-color: white;
    width: 100px;
    height: 100px;
}

.cq-profile-pic>img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.cq-name-details {
    border-radius: 5px;
    background-color: white;
    margin-left: 10%;
    height: 60px;
    margin-top: 2%;
    text-align: center;
    font-size: 18px;
    padding-left: 5%;
    padding-top: 2%;
    padding-right: 5%;
    display: table;
    width: 100%;
}

.cq-name-details>span {
    display: table-cell;
    text-align: center;
}

.cq-upload-chq {
    background-color: white;
    padding: 3%;
    border-radius: 5px;
    max-height: 200%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border: 1px solid; */
    padding-left: 5%;
}

.cq-chq-upload-box {
    background-color: lightblue;
    border: 3px blue;
    border-style: dashed;
    width: 100%;
    padding: 5%;
    /* height: 20%; */
    /* margin: 10%; */
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.cq-chq-upload-box input.upload {
    cursor: pointer;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.cq-vertical-line {
    border-left: 2px solid lightgray;
    height: 400px;
    margin-left: 5%;
    margin-right: 5%;
}

.cq-bank-acc-details {
    width: 80%;
    /* text-align: center; */
    font-size: 16px;
    height: 50px;
}

.cq-chq-details-block {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.chq-no-block {
    width: 40%;
}

.purpose-block {
    width: 60%;
}

.chq-inputs {
    margin-top: 1%;
}

.cq-bank-acc-details{
    display: block;
}


.chq_details_loan_block {
    /* background-color: white; */
    border-radius: 5px;
    border: 1px dashed grey;
    padding: 2%;
    /* padding-bottom: 1%; */
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

.chq_det_loan_block_inner {
    display: flex;
    flex-direction: row;
}

.save_chq_details_block {
    width: 70%;
    margin-right: 1%;
    background-color: white;
    padding: 2%;
    padding-left: 5%;
    border-radius: 5px;
    text-align: left;
    display: flex;
    flex-direction: row;
}

.hh_loan_id_block {
    width: 30%;
    background-color: white;
    border-radius: 5px;
    padding: 2%;
}

.assign-btn {
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
}

.chq-number-block {
    background-color: #fffff0;
    border-radius: 2px;
    padding: 1%;
    font-weight: bold;
    margin-top: 1%;
    margin-bottom: 1%;
}

.saved_chq_det_block {
    width: 60%;
}

.saved_chq_purpose_block {
    margin: auto;
    text-align: center;
    width: 20%;
}

.errorMessage {
    color: red;
    font-weight: bold;
}

.save_qc_div {
    margin-top: 2%;
}

.sel_loan_ids {
    background-color: #F5F5F5;
    border: 2px solid #F0F0F0;
    margin: 1%;
}

.cheque_upload_details {
    min-width: 30%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ca-header-box-cheque-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    font-size: 19px;
    font-weight: 500;
    color: black;
    margin-bottom: 20px;
}

.uploaded-chq-name {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #E8E8E8;
    padding: 14px;
    display: inline-block;
    word-wrap: break-word;
}

.downloadLink {
    text-decoration: underline;
    cursor: pointer;
}

.editDeleteSelect .saved_chq_purpose_block {
    display: block;
}

.cq-name-details-mob {
    display: none;

}

.hh_loan_id_block_mob {
    display: none;
}


.saved_chq_purpose_block_mob {
    display: none;
}

.save_cheque_details_mob {
    display: none;
}


@media (max-width: 390px) {
    .cq-name-details {
        background-color: yellow;
    }
}

@media(max-width:412px) {
    .cq-name-details {
        display: none;
    }

    .cq-name-details-mob {
        display: block;
        border-radius: 5px;
        background-color: white;
        margin-left: 5%;
        height: 60px;
        margin-top: 5%;
        text-align: center;
        font-size: 14px;
        padding-left: 5%;
        padding-top: 2%;
        padding-right: 5%;
        display: table;
        width: 100%;
    }

    .chq_details_loan_block {
        /* background-color: white; */
        border-radius: 5px;
        border: 1px dashed grey;
        padding: 2%;
        /* padding-bottom: 1%; */
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;
    }

    .chq_det_loan_block_inner {
        display: flex;
        flex-direction: column;
    }

    .save_chq_details_block {
        width: 100%;
        margin-right: 1%;
        background-color: white;
        padding: 2%;
        padding-left: 5%;
        border-radius: 5px;
        text-align: left;
        display: flex;
        flex-direction: row;
        margin-bottom: 2%;
    }

    .saved_chq_purpose_block {
        margin: auto;
        text-align: center;
        width: 20%;
    }

    .downloadLink {
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
    }

    .editDeleteSelect {
        display: none;
    }

    .saved_chq_purpose_block {
        display: none;
    }

    .saved_chq_purpose_block_mob {
        display: block;
        margin-left: 5%;
    }

    .hh_loan_id_block {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 2%;
    }

    .assign-btn {
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .cq-upload-chq {
        background-color: white;
        padding: 3%;
        border-radius: 5px;
        /* max-height: 200%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 1px solid; */
        padding-left: 5%;
    }

    .cq-vertical-line {
        display: none;
    }

    .hh_loan_id_block_mob {
        display: block;
        width: 100%;
        padding: 2%;
    }

    .cq-chq-upload-box {
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        padding: 5%;

    }

    .cheque_upload_details {
        display: none;
    }

    .save_cheque_details_mob {
        display: block;
        
    }

    .chq_add_header_mob {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .chq_add_block_mob {
        display: flex;
        flex-direction: row;
       
    }
    
    .cq-bank-acc-details{
        display: none;
    }

    .purpose-block{
        display: flex;
        flex-direction: column;
        padding-left: 10%;
        width: 50%;
    }
    
    .chq-no-block{
        width: 60%;
    }
}
.container-payments {
  margin: auto;
  border-radius: 11px;
  background-color: #fafafa;
  box-shadow: 0 0 6px #eeebeb;
  display: flex;
  max-width: 801px;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  padding: 17px 20px;
}

.label {
  color: #000;
  font-size: 23px;
  font-weight: 600;
  align-self: start;
}

.input {
  border-radius: 7px;
  background-color: #fff;
  margin-top: 11px;
  font-size: 11px;
  color: black;
  font-weight: 400;
  padding: 12px 11px;
  border: 1px solid rgba(11, 115, 140, 0.66);
}

.button {
  border-radius: 7px;
  background-color: #13697e;
  align-self: center;
  margin-top: 19px;
  width: 165px;
  max-width: 100%;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  padding: 11px 28px;
  border: 1px solid rgba(11, 115, 140, 0.66);
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.card_footer {
  /* display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 1.25em 1.6em; */
  padding: 10px 0 10px 0;
  font-size: 0.9rem;
  color: #a7a6a6;
}

.card_footer ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.card_btn {
  display: flex;
  justify-content: center;
  padding: 1.25em 1.6em;
  color: white;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
}

.maskNum {
  display: flex;
  justify-content: center;
}

.mobile {
  position: relative;
  top: 13px;
  font-size: 18px;
}

.card-header {
  background-color: rgba(11, 115, 140, 0.66);
  width: 100%;
}

.payments-confirm-box {
  padding: 0 !important;

  .card-head {
    background-color: #13697e;
    padding: 20px;
    display: flex;
    justify-content: end;
  }
}

#rzp-button1 {
  color: white;
  width: 150px;
}

.card_footer_left {
  font-size: 18px;
}

.sbmt-btn {
  margin-top: 13px;
}

.amtinCard {
  align-self: center;
  font-size: 20px;
}

.textcenter {
  text-align: -webkit-center;
}

._failed {
  border-bottom: solid 4px red !important;
}

._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

.message {
  font-size: 15px;
  font-weight: 500;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

.spinbody {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  background: rgb(23 22 22 / 39%);
  transition: opacity 0.3s linear;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pencil {
  display: block;
  width: 10em;
  height: 10em;
}

.pencil__body1,
.pencil__body2,
.pencil__body3,
.pencil__eraser,
.pencil__eraser-skew,
.pencil__point,
.pencil__rotate,
.pencil__stroke {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.pencil__body1,
.pencil__body2,
.pencil__body3 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.pencil__body1 {
  -webkit-animation-name: pencilBody1;
          animation-name: pencilBody1;
}

.pencil__body2 {
  -webkit-animation-name: pencilBody2;
          animation-name: pencilBody2;
}

.pencil__body3 {
  -webkit-animation-name: pencilBody3;
          animation-name: pencilBody3;
}

.pencil__eraser {
  -webkit-animation-name: pencilEraser;
          animation-name: pencilEraser;
  -webkit-transform: rotate(-90deg) translate(49px, 0);
          transform: rotate(-90deg) translate(49px, 0);
}

.pencil__eraser-skew {
  -webkit-animation-name: pencilEraserSkew;
          animation-name: pencilEraserSkew;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.pencil__point {
  -webkit-animation-name: pencilPoint;
          animation-name: pencilPoint;
  -webkit-transform: rotate(-90deg) translate(49px, -30px);
          transform: rotate(-90deg) translate(49px, -30px);
}

.pencil__rotate {
  -webkit-animation-name: pencilRotate;
          animation-name: pencilRotate;
}

.pencil__stroke {
  -webkit-animation-name: pencilStroke;
          animation-name: pencilStroke;
  -webkit-transform: translate(100px, 100px) rotate(-113deg);
          transform: translate(100px, 100px) rotate(-113deg);
}

/* Animations */
@-webkit-keyframes pencilBody1 {

  from,
  to {
    stroke-dashoffset: 351.86;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 150.8;
    /* 3/8 of diameter */
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }
}
@keyframes pencilBody1 {

  from,
  to {
    stroke-dashoffset: 351.86;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 150.8;
    /* 3/8 of diameter */
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }
}

@-webkit-keyframes pencilBody2 {

  from,
  to {
    stroke-dashoffset: 406.84;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 174.36;
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }
}

@keyframes pencilBody2 {

  from,
  to {
    stroke-dashoffset: 406.84;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 174.36;
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }
}

@-webkit-keyframes pencilBody3 {

  from,
  to {
    stroke-dashoffset: 296.88;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 127.23;
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }
}

@keyframes pencilBody3 {

  from,
  to {
    stroke-dashoffset: 296.88;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 127.23;
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }
}

@-webkit-keyframes pencilEraser {

  from,
  to {
    -webkit-transform: rotate(-45deg) translate(49px, 0);
            transform: rotate(-45deg) translate(49px, 0);
  }

  50% {
    -webkit-transform: rotate(0deg) translate(49px, 0);
            transform: rotate(0deg) translate(49px, 0);
  }
}

@keyframes pencilEraser {

  from,
  to {
    -webkit-transform: rotate(-45deg) translate(49px, 0);
            transform: rotate(-45deg) translate(49px, 0);
  }

  50% {
    -webkit-transform: rotate(0deg) translate(49px, 0);
            transform: rotate(0deg) translate(49px, 0);
  }
}

@-webkit-keyframes pencilEraserSkew {

  from,
  32.5%,
  67.5%,
  to {
    -webkit-transform: skewX(0);
            transform: skewX(0);
  }

  35%,
  65% {
    -webkit-transform: skewX(-4deg);
            transform: skewX(-4deg);
  }

  37.5%,
  62.5% {
    -webkit-transform: skewX(8deg);
            transform: skewX(8deg);
  }

  40%,
  45%,
  50%,
  55%,
  60% {
    -webkit-transform: skewX(-15deg);
            transform: skewX(-15deg);
  }

  42.5%,
  47.5%,
  52.5%,
  57.5% {
    -webkit-transform: skewX(15deg);
            transform: skewX(15deg);
  }
}

@keyframes pencilEraserSkew {

  from,
  32.5%,
  67.5%,
  to {
    -webkit-transform: skewX(0);
            transform: skewX(0);
  }

  35%,
  65% {
    -webkit-transform: skewX(-4deg);
            transform: skewX(-4deg);
  }

  37.5%,
  62.5% {
    -webkit-transform: skewX(8deg);
            transform: skewX(8deg);
  }

  40%,
  45%,
  50%,
  55%,
  60% {
    -webkit-transform: skewX(-15deg);
            transform: skewX(-15deg);
  }

  42.5%,
  47.5%,
  52.5%,
  57.5% {
    -webkit-transform: skewX(15deg);
            transform: skewX(15deg);
  }
}

@-webkit-keyframes pencilPoint {

  from,
  to {
    -webkit-transform: rotate(-90deg) translate(49px, -30px);
            transform: rotate(-90deg) translate(49px, -30px);
  }

  50% {
    -webkit-transform: rotate(-225deg) translate(49px, -30px);
            transform: rotate(-225deg) translate(49px, -30px);
  }
}

@keyframes pencilPoint {

  from,
  to {
    -webkit-transform: rotate(-90deg) translate(49px, -30px);
            transform: rotate(-90deg) translate(49px, -30px);
  }

  50% {
    -webkit-transform: rotate(-225deg) translate(49px, -30px);
            transform: rotate(-225deg) translate(49px, -30px);
  }
}

@-webkit-keyframes pencilRotate {
  from {
    -webkit-transform: translate(100px, 100px) rotate(0);
            transform: translate(100px, 100px) rotate(0);
  }

  to {
    -webkit-transform: translate(100px, 100px) rotate(720deg);
            transform: translate(100px, 100px) rotate(720deg);
  }
}

@keyframes pencilRotate {
  from {
    -webkit-transform: translate(100px, 100px) rotate(0);
            transform: translate(100px, 100px) rotate(0);
  }

  to {
    -webkit-transform: translate(100px, 100px) rotate(720deg);
            transform: translate(100px, 100px) rotate(720deg);
  }
}

@-webkit-keyframes pencilStroke {
  from {
    stroke-dashoffset: 439.82;
    -webkit-transform: translate(100px, 100px) rotate(-113deg);
            transform: translate(100px, 100px) rotate(-113deg);
  }

  50% {
    stroke-dashoffset: 164.93;
    -webkit-transform: translate(100px, 100px) rotate(-113deg);
            transform: translate(100px, 100px) rotate(-113deg);
  }

  75%,
  to {
    stroke-dashoffset: 439.82;
    -webkit-transform: translate(100px, 100px) rotate(112deg);
            transform: translate(100px, 100px) rotate(112deg);
  }
}

@keyframes pencilStroke {
  from {
    stroke-dashoffset: 439.82;
    -webkit-transform: translate(100px, 100px) rotate(-113deg);
            transform: translate(100px, 100px) rotate(-113deg);
  }

  50% {
    stroke-dashoffset: 164.93;
    -webkit-transform: translate(100px, 100px) rotate(-113deg);
            transform: translate(100px, 100px) rotate(-113deg);
  }

  75%,
  to {
    stroke-dashoffset: 439.82;
    -webkit-transform: translate(100px, 100px) rotate(112deg);
            transform: translate(100px, 100px) rotate(112deg);
  }
}

.purchase-section {
  position: relative;
  overflow: visible;
  padding: 0 1em 1em 1em;
  background: #e7e7e7;
  border-top-left-radius: 0.8em;
  border-top-right-radius: 0.8em;
}

.purchase-section:before {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  left: -0.8em;
  bottom: -0.8em;
  background: #ffffff;
}

.purchase-section:after {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  right: -0.8em;
  bottom: -0.8em;
  background: #ffffff;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-fill {
  display: flex;
  flex: 1 1;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.purchase-props {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  width: 100%;
}

.purchase-props li {
  width: 100%;
  line-height: 2.5;
}

.purchase-props li span {
  color: var(--secondary-text);
  font-weight: 600;
  font-size: 15px;
}

.separation-line {
  border-top: 1px dashed #aaa;
  margin: 0 0.8em;
}

.total-section {
  position: relative;
  overflow: hidden;

  padding: 1em;
  background: #e7e7e7;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
}

.total-section:before {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  left: -0.8em;
  top: -0.8em;
  background: #ffffff;
}

.total-section:after {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  right: -0.8em;
  top: -0.8em;
  background: #ffffff;
}

.total-label {
  font-size: 0.8em;
  padding-bottom: 0.5em;
}

.total-section strong {
  font-size: 1.5em;
  font-weight: 800;
}

.total-section small {
  font-weight: 600;
}
