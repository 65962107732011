.text-right{
    margin-right: 5%;
}


.cq-blue-box {
    background-color: #EFF5F6;
    width: 100%;
    height: auto;
    padding: 3%;
    text-align: center;
    border-radius: 20px;
}

.cq-profile-pic {
    border-radius: 50%;
    background-color: white;
    width: 100px;
    height: 100px;
}

.cq-profile-pic>img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.cq-name-details {
    border-radius: 5px;
    background-color: white;
    margin-left: 10%;
    height: 60px;
    margin-top: 2%;
    text-align: center;
    font-size: 18px;
    padding-left: 5%;
    padding-top: 2%;
    padding-right: 5%;
    display: table;
    width: 100%;
}

.cq-name-details>span {
    display: table-cell;
    text-align: center;
}

.cq-upload-chq {
    background-color: white;
    padding: 3%;
    border-radius: 5px;
    max-height: 200%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border: 1px solid; */
    padding-left: 5%;
}

.cq-chq-upload-box {
    background-color: lightblue;
    border: 3px blue;
    border-style: dashed;
    width: 100%;
    padding: 5%;
    /* height: 20%; */
    /* margin: 10%; */
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.cq-chq-upload-box input.upload {
    cursor: pointer;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.cq-vertical-line {
    border-left: 2px solid lightgray;
    height: 400px;
    margin-left: 5%;
    margin-right: 5%;
}

.cq-bank-acc-details {
    width: 80%;
    /* text-align: center; */
    font-size: 16px;
    height: 50px;
}

.cq-chq-details-block {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.chq-no-block {
    width: 40%;
}

.purpose-block {
    width: 60%;
}

.chq-inputs {
    margin-top: 1%;
}

.cq-bank-acc-details{
    display: block;
}


.chq_details_loan_block {
    /* background-color: white; */
    border-radius: 5px;
    border: 1px dashed grey;
    padding: 2%;
    /* padding-bottom: 1%; */
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

.chq_det_loan_block_inner {
    display: flex;
    flex-direction: row;
}

.save_chq_details_block {
    width: 70%;
    margin-right: 1%;
    background-color: white;
    padding: 2%;
    padding-left: 5%;
    border-radius: 5px;
    text-align: left;
    display: flex;
    flex-direction: row;
}

.hh_loan_id_block {
    width: 30%;
    background-color: white;
    border-radius: 5px;
    padding: 2%;
}

.assign-btn {
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
}

.chq-number-block {
    background-color: #fffff0;
    border-radius: 2px;
    padding: 1%;
    font-weight: bold;
    margin-top: 1%;
    margin-bottom: 1%;
}

.saved_chq_det_block {
    width: 60%;
}

.saved_chq_purpose_block {
    margin: auto;
    text-align: center;
    width: 20%;
}

.errorMessage {
    color: red;
    font-weight: bold;
}

.save_qc_div {
    margin-top: 2%;
}

.sel_loan_ids {
    background-color: #F5F5F5;
    border: 2px solid #F0F0F0;
    margin: 1%;
}

.cheque_upload_details {
    min-width: 30%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ca-header-box-cheque-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    font-size: 19px;
    font-weight: 500;
    color: black;
    margin-bottom: 20px;
}

.uploaded-chq-name {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #E8E8E8;
    padding: 14px;
    display: inline-block;
    word-wrap: break-word;
}

.downloadLink {
    text-decoration: underline;
    cursor: pointer;
}

.editDeleteSelect .saved_chq_purpose_block {
    display: block;
}

.cq-name-details-mob {
    display: none;

}

.hh_loan_id_block_mob {
    display: none;
}


.saved_chq_purpose_block_mob {
    display: none;
}

.save_cheque_details_mob {
    display: none;
}


@media (max-width: 390px) {
    .cq-name-details {
        background-color: yellow;
    }
}

@media(max-width:412px) {
    .cq-name-details {
        display: none;
    }

    .cq-name-details-mob {
        display: block;
        border-radius: 5px;
        background-color: white;
        margin-left: 5%;
        height: 60px;
        margin-top: 5%;
        text-align: center;
        font-size: 14px;
        padding-left: 5%;
        padding-top: 2%;
        padding-right: 5%;
        display: table;
        width: 100%;
    }

    .chq_details_loan_block {
        /* background-color: white; */
        border-radius: 5px;
        border: 1px dashed grey;
        padding: 2%;
        /* padding-bottom: 1%; */
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;
    }

    .chq_det_loan_block_inner {
        display: flex;
        flex-direction: column;
    }

    .save_chq_details_block {
        width: 100%;
        margin-right: 1%;
        background-color: white;
        padding: 2%;
        padding-left: 5%;
        border-radius: 5px;
        text-align: left;
        display: flex;
        flex-direction: row;
        margin-bottom: 2%;
    }

    .saved_chq_purpose_block {
        margin: auto;
        text-align: center;
        width: 20%;
    }

    .downloadLink {
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
    }

    .editDeleteSelect {
        display: none;
    }

    .saved_chq_purpose_block {
        display: none;
    }

    .saved_chq_purpose_block_mob {
        display: block;
        margin-left: 5%;
    }

    .hh_loan_id_block {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 2%;
    }

    .assign-btn {
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .cq-upload-chq {
        background-color: white;
        padding: 3%;
        border-radius: 5px;
        /* max-height: 200%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 1px solid; */
        padding-left: 5%;
    }

    .cq-vertical-line {
        display: none;
    }

    .hh_loan_id_block_mob {
        display: block;
        width: 100%;
        padding: 2%;
    }

    .cq-chq-upload-box {
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        padding: 5%;

    }

    .cheque_upload_details {
        display: none;
    }

    .save_cheque_details_mob {
        display: block;
        
    }

    .chq_add_header_mob {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .chq_add_block_mob {
        display: flex;
        flex-direction: row;
       
    }
    
    .cq-bank-acc-details{
        display: none;
    }

    .purpose-block{
        display: flex;
        flex-direction: column;
        padding-left: 10%;
        width: 50%;
    }
    
    .chq-no-block{
        width: 60%;
    }
}