.hh_white_block_nach {
    background-color: white;
    width: 100%;
    /* height: 310px; */
    margin: auto;
    margin-top: 5%;
    border-radius: 20px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 2%;
    position: relative;
  }

.name_hh_block_nach {
    /* background-color: red; */
    padding: 5% 5% 5% 5%;
    /* margin: 10px; */
    color: white;
    margin-bottom: 5px;
    display: flex;
    border-radius: 10px 10px 0px 0px;
    /* border-radius: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    justify-content: space-between;
    background-color: #1FA1C1;
}

.upload_icon_bg_nach {
    all: unset;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #13697E;
    cursor: pointer;
    position: absolute;
    right: 5%;
    flex-shrink: 0;
}

.upload_icon_bg_upi {
    all: unset;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    cursor: pointer;
    position: absolute;
    right: 11%;
    flex-shrink: 0;
}

.nach_icon_img {
    width: 60%;
}

.bank_details_nach {
    border: 1px solid black;
    border-radius: 10px;
    margin: 3% 5% 2% 5%;
    /* padding: 2%; */
}

.bank_detail_nach {
    display: flex;
    margin: 1%;
}

.bank_data {
    flex: 0 0 50%;
    padding: 10px;
}

.dotted_line_nach{
    border: 1px dashed black;
    width: 90%;
}

.bank_detail_bottom_block {
    /* background-color: red; */
    padding: 5% 5% 5% 5%;
    /* margin: 10px; */
    width: 100%;
    /* margin-bottom: 5px; */
    display: flex;
    border-radius: 0px 0px 10px 10px;
    /* border-radius: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    justify-content: center;
    background-color: #13697E;
    color: white;
}

.nach_status_dt{
    color: white;
    text-align: center;
}