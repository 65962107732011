.container-payments {
  margin: auto;
  border-radius: 11px;
  background-color: #fafafa;
  box-shadow: 0 0 6px #eeebeb;
  display: flex;
  max-width: 801px;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  padding: 17px 20px;
}

.label {
  color: #000;
  font-size: 23px;
  font-weight: 600;
  align-self: start;
}

.input {
  border-radius: 7px;
  background-color: #fff;
  margin-top: 11px;
  font-size: 11px;
  color: black;
  font-weight: 400;
  padding: 12px 11px;
  border: 1px solid rgba(11, 115, 140, 0.66);
}

.button {
  border-radius: 7px;
  background-color: #13697e;
  align-self: center;
  margin-top: 19px;
  width: 165px;
  max-width: 100%;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  padding: 11px 28px;
  border: 1px solid rgba(11, 115, 140, 0.66);
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.card_footer {
  /* display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 1.25em 1.6em; */
  padding: 10px 0 10px 0;
  font-size: 0.9rem;
  color: #a7a6a6;
}

.card_footer ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.card_btn {
  display: flex;
  justify-content: center;
  padding: 1.25em 1.6em;
  color: white;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
}

.maskNum {
  display: flex;
  justify-content: center;
}

.mobile {
  position: relative;
  top: 13px;
  font-size: 18px;
}

.card-header {
  background-color: rgba(11, 115, 140, 0.66);
  width: 100%;
}

.payments-confirm-box {
  padding: 0 !important;

  .card-head {
    background-color: #13697e;
    padding: 20px;
    display: flex;
    justify-content: end;
  }
}

#rzp-button1 {
  color: white;
  width: 150px;
}

.card_footer_left {
  font-size: 18px;
}

.sbmt-btn {
  margin-top: 13px;
}

.amtinCard {
  align-self: center;
  font-size: 20px;
}

.textcenter {
  text-align: -webkit-center;
}

._failed {
  border-bottom: solid 4px red !important;
}

._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

.message {
  font-size: 15px;
  font-weight: 500;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

.spinbody {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  background: rgb(23 22 22 / 39%);
  transition: opacity 0.3s linear;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pencil {
  display: block;
  width: 10em;
  height: 10em;
}

.pencil__body1,
.pencil__body2,
.pencil__body3,
.pencil__eraser,
.pencil__eraser-skew,
.pencil__point,
.pencil__rotate,
.pencil__stroke {
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pencil__body1,
.pencil__body2,
.pencil__body3 {
  transform: rotate(-90deg);
}

.pencil__body1 {
  animation-name: pencilBody1;
}

.pencil__body2 {
  animation-name: pencilBody2;
}

.pencil__body3 {
  animation-name: pencilBody3;
}

.pencil__eraser {
  animation-name: pencilEraser;
  transform: rotate(-90deg) translate(49px, 0);
}

.pencil__eraser-skew {
  animation-name: pencilEraserSkew;
  animation-timing-function: ease-in-out;
}

.pencil__point {
  animation-name: pencilPoint;
  transform: rotate(-90deg) translate(49px, -30px);
}

.pencil__rotate {
  animation-name: pencilRotate;
}

.pencil__stroke {
  animation-name: pencilStroke;
  transform: translate(100px, 100px) rotate(-113deg);
}

/* Animations */
@keyframes pencilBody1 {

  from,
  to {
    stroke-dashoffset: 351.86;
    transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 150.8;
    /* 3/8 of diameter */
    transform: rotate(-225deg);
  }
}

@keyframes pencilBody2 {

  from,
  to {
    stroke-dashoffset: 406.84;
    transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 174.36;
    transform: rotate(-225deg);
  }
}

@keyframes pencilBody3 {

  from,
  to {
    stroke-dashoffset: 296.88;
    transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 127.23;
    transform: rotate(-225deg);
  }
}

@keyframes pencilEraser {

  from,
  to {
    transform: rotate(-45deg) translate(49px, 0);
  }

  50% {
    transform: rotate(0deg) translate(49px, 0);
  }
}

@keyframes pencilEraserSkew {

  from,
  32.5%,
  67.5%,
  to {
    transform: skewX(0);
  }

  35%,
  65% {
    transform: skewX(-4deg);
  }

  37.5%,
  62.5% {
    transform: skewX(8deg);
  }

  40%,
  45%,
  50%,
  55%,
  60% {
    transform: skewX(-15deg);
  }

  42.5%,
  47.5%,
  52.5%,
  57.5% {
    transform: skewX(15deg);
  }
}

@keyframes pencilPoint {

  from,
  to {
    transform: rotate(-90deg) translate(49px, -30px);
  }

  50% {
    transform: rotate(-225deg) translate(49px, -30px);
  }
}

@keyframes pencilRotate {
  from {
    transform: translate(100px, 100px) rotate(0);
  }

  to {
    transform: translate(100px, 100px) rotate(720deg);
  }
}

@keyframes pencilStroke {
  from {
    stroke-dashoffset: 439.82;
    transform: translate(100px, 100px) rotate(-113deg);
  }

  50% {
    stroke-dashoffset: 164.93;
    transform: translate(100px, 100px) rotate(-113deg);
  }

  75%,
  to {
    stroke-dashoffset: 439.82;
    transform: translate(100px, 100px) rotate(112deg);
  }
}

.purchase-section {
  position: relative;
  overflow: visible;
  padding: 0 1em 1em 1em;
  background: #e7e7e7;
  border-top-left-radius: 0.8em;
  border-top-right-radius: 0.8em;
}

.purchase-section:before {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  left: -0.8em;
  bottom: -0.8em;
  background: #ffffff;
}

.purchase-section:after {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  right: -0.8em;
  bottom: -0.8em;
  background: #ffffff;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-fill {
  display: flex;
  flex: 1 1;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.purchase-props {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  width: 100%;
}

.purchase-props li {
  width: 100%;
  line-height: 2.5;
}

.purchase-props li span {
  color: var(--secondary-text);
  font-weight: 600;
  font-size: 15px;
}

.separation-line {
  border-top: 1px dashed #aaa;
  margin: 0 0.8em;
}

.total-section {
  position: relative;
  overflow: hidden;

  padding: 1em;
  background: #e7e7e7;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
}

.total-section:before {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  left: -0.8em;
  top: -0.8em;
  background: #ffffff;
}

.total-section:after {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  right: -0.8em;
  top: -0.8em;
  background: #ffffff;
}

.total-label {
  font-size: 0.8em;
  padding-bottom: 0.5em;
}

.total-section strong {
  font-size: 1.5em;
  font-weight: 800;
}

.total-section small {
  font-weight: 600;
}